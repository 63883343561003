import React from "react";
import { Form, Row, Col, Dropdown, Modal, Button } from "react-bootstrap";
import { useState } from "react";
import { apiCall } from "../../../../_services/apiCall";
import config from "../../../../config/config.json";
import { useEffect } from "react";
import swal from "sweetalert";
import { FaEdit } from "react-icons/fa";
import { FaFileDownload, FaTrash } from "react-icons/fa";
import { DetailModal } from "./DetailModal";

const YesNoComponent = ({
  sourceData,
  answerId,
  financeObject,
  menu,
  currentUserId,
  assignedToDetails,
  savedAnswers,
  auditorId,
  answer,
  getAuditListing,
  setAnswer,
  item,
  selectedPeriod,
  toDate,
  fromDate,
}) => {
  const [response, setResponse] = useState();
  const [showWeblink, setShowWeblink] = useState(false);
  const [showNote, setShowNote] = useState(item?.note);
  const [details, setDetails] = useState(item?.details || "");
  const [weblink, setWeblink] = useState(answer?.weblink || "");
  const [reasoning, setReasoning] = useState(answer?.details || "");
  const [answerIdReal, setAnswerIdReal] = useState();
  const [status, setStatus] = useState();
  const [edit, setEdit] = useState(true);
  const [check, setCheck] = useState(false);
  const [note, setNote] = useState(answer?.note || [['']]);
  const [answerObject, setAnswerObject] = useState({ answer: "" });
  const [answerr, setAnswerr] = useState({ answer: "" });
  const [auditButton,setAuditButton] = useState(false)
  const [proofDocument, setProofDocument] = useState(
    answer?.proofDocument || []
  );
  const [remark, setRemark] = useState("");
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [dynamicInputs, setDynamicInputs] = useState(
    item?.details.reduce((acc, detail) => {
      acc[detail.option_type] = answer?.[detail.option_type] || "";
      return acc;
    }, {})
  );
  const [showData, setShowData] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDoubleClick = (data) => {
    setShowData(data);
    setIsModalOpen(true);
  };

  const initializeAnswer = () => {
    if (item && item.questionId && savedAnswers && savedAnswers.length) {
      console.log()
      let matchedAnswer;
      if (item?.frequency == "CUSTOM") {
        matchedAnswer = savedAnswers.find(
          (savedAnswer) =>
            savedAnswer.questionId == item.questionId &&
            savedAnswer.toDate == toDate &&
            savedAnswer.fromDate == fromDate
        );
        setAnswerIdReal(matchedAnswer?.id);
      } else {
       

        matchedAnswer = savedAnswers.find(
          (savedAnswer) => savedAnswer.questionId == item.questionId
        );
        setAnswerIdReal(matchedAnswer?.id);
      }
      if (matchedAnswer) {
        setCheck(true);
        setAnswerIdReal(matchedAnswer?.id);
        setStatus(matchedAnswer?.status);
        try {
          // Log the value before parsing
          console.log("matchedAnswer.answer:", matchedAnswer.answer);
        
          // Safely parse the JSON data
          let parsedAnswer;
          try {
            parsedAnswer = JSON.parse(matchedAnswer.answer);
            console.log("Parsed answer:", parsedAnswer);
          } catch (error) {
            console.error("Error parsing matchedAnswer.answer:", error);
            throw error; // Rethrow to catch in the outer block
          }
        
          // Update state with parsed data
          setAnswerObject(parsedAnswer);
          setResponse(parsedAnswer?.answer);
        
          if (parsedAnswer?.answer === "Yes") {
            setShowWeblink(true);
          }
        
          setNote((prevState) => {
            if (typeof matchedAnswer.note === "string") {
              return [[matchedAnswer.note]]; // Wrap the string in a 2D array
            }
            if (Array.isArray(matchedAnswer.note) && Array.isArray(matchedAnswer.note[0])) {
              return matchedAnswer.note; // Use it as is
            }
            return [[""]]; // Return an empty 2D array if not string or 2D array
          });
        
          if (Array.isArray(matchedAnswer.proofDocument)) {
            if (Array.isArray(matchedAnswer.proofDocument[0])) {
              setProofDocument(matchedAnswer.proofDocument); // 2D array
            } else {
              setProofDocument([matchedAnswer.proofDocument]); // Convert 1D array to 2D
            }
          } else {
            setProofDocument([[]]); // Default to an empty 2D array
          }
        
          setAnswer((prevState) => ({
            ...prevState,
            questionId: item?.questionId,
            moduleId: item?.moduleId,
            questionType: item?.questionType,
            frequency: item?.frequency,
            response: matchedAnswer.answer || "",
            note: (() => {
              if (typeof matchedAnswer.note === "string") {
                return [[matchedAnswer.note]]; // Wrap the string in a 2D array
              }
              if (Array.isArray(matchedAnswer.note) && Array.isArray(matchedAnswer.note[0])) {
                return matchedAnswer.note; // Use it as is
              }
              return [[""]]; // Return an empty 2D array if not string or 2D array
            })(),
            proofDocument: matchedAnswer.proofDocument || [[]],
          }));
        
        } catch (error) {
          console.error("Error in try block:", error);
        }
      } else {
        setResponse();
        setAnswer((prevState) => ({
          ...prevState,
          questionId: item?.questionId,
          moduleId: item?.moduleId,
          questionType: item?.questionType,
          frequency: item?.frequency,
          note:[['']]
      
   
      
        }));

        setNote([[""]]);
        setProofDocument([[]]);
      }
    }
  };

  useEffect(() => {
    initializeAnswer();
  }, [savedAnswers, item, toDate, fromDate]);

  const handleRemarkChange = (e) => {
    const newRemark = e.target.value;
    setRemark(newRemark);
  };
  const handleResponseSelect = (key) => {
    setResponse(key);
    setShowWeblink(key === "Yes");

    setAnswerObject((prevAnswerr) => ({
      ...prevAnswerr,
      answer: key,
    }));
  };

  const handleAccept = async () => {
    const { isSuccess, error, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}validateAnswers`,
      {},
      {
        questionId: item.questionId,
        answerId: answerIdReal,
        questionType: item.questionType,
        remark: remark,
        validation: "ACCEPTED",
        financialYearId: financeObject,
      },
      "POST"
    );

    if (isSuccess) {
      setAuditButton(true)
      getAuditListing()
    }

    if (error) {
      swal({
        icon: "error",
        title: data.message,
        timer: 1000,
      });
    }
  };

  const handleReject = async () => {
    if (remark) {
      const { isSuccess, error, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}validateAnswers`,
        {},
        {
          questionId: item.questionId,
          answerId: answerIdReal,
          questionType: item.questionType,
          remark: remark,
          validation: "REJECTED",
          financialYearId: financeObject,
        },
        "POST"
      );

      if (isSuccess) {
        getAuditListing();
      }

      if (error) {
        swal({
          icon: "error",
          title: data.message,
          timer: 1000,
        });
      }
    } else {
      swal({
        icon: "error",
        title: "Please enter remark",
        timer: 1000,
      });
    }
  };

  const handleEditClick = () => {
    setEdit(!edit);
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent form from refreshing the page
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}saveAnswerReportingQuestion`,
      {},
      {
        ...answer,
        answer: JSON.stringify({ ...answerObject }),
      },
      "POST"
    );

    if (isSuccess) {
    }
  };
  const uploadFile = async (files) => {
    const selectedFile = files.target.files[0];
    const timestamp = new Date().getTime(); // Generate a timestamp
    const fileName = `${timestamp}_${selectedFile.name}`;

    const formdata = new FormData();
    formdata.append("file", selectedFile);
    formdata.append("fileName", fileName);
    formdata.append("filePath", "yasil/");

    const requestOptions = {
      header: {
        "Content-Type": "multipart/form-data", // Set the Content-Type header
      },
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${
          config.AUTH_API_URL_COMPANY
        }uploadFile?current_role=${localStorage.getItem("role")}`,
        requestOptions
      );
      const result = await response.text();
      const url = JSON.parse(result);

      // Append the new file URL to the list of proofDocument
      setProofDocument((prevState) => {
        const newProofDocument = [...prevState];

        if (!newProofDocument[0]) {
          newProofDocument[0] = [];
        }

        newProofDocument[0] = [...newProofDocument[0], url.url];

        return newProofDocument;
      });
      setAnswer((prevState) => {
        const newProofDocument = [...prevState.proofDocument];

        // If currentPage doesn't exist yet, initialize it as an empty array
        if (!newProofDocument[0]) {
          newProofDocument[0] = [];
        }

        // Update the specific page
        newProofDocument[0] = [...newProofDocument[0], url.url];

        return {
          ...prevState,
          questionId: item?.questionId,
          moduleId: item?.moduleId,
          questionType: item?.questionType,
          frequency: item?.frequency,
          proofDocument: newProofDocument,
        };
      });
    } catch (error) {}
  };
  const confirmDelete = () => {
    setProofDocument((prevState) => {
      // Clone the current proofDocument array
      const newProofDocument = [...prevState];

      // Update the specific page by filtering out the fileToDelete
      newProofDocument[0] = newProofDocument[0].filter(
        (url) => url !== fileToDelete
      );

      return newProofDocument;
    });
    setShowModal(false);
    setFileToDelete(null); // Clear the file to delete
  };

  // Cancel deletion
  const cancelDelete = () => {
    setShowModal(false);
    setFileToDelete(null);
  };

  const handleDeleteClick = (url) => {
    setFileToDelete(url);
    setShowModal(true);
  };

  const handleFileDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank"; // Opens in a new tab
    link.download = url.split("/").pop();
    document.body.appendChild(link); // Append to body
    link.click();
    document.body.removeChild(link); // Clean up
  };

  const handleNoteChange = (e) => {
    const newNote = e.target.value;
    setNote([[newNote]]);
    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      questionId: item?.questionId,
      moduleId: item?.moduleId,
      questionType: item?.questionType,
      frequency: item?.frequency,
      note: [[newNote]],
    }));
  };

  const handleDynamicInputChange = (id, value, optionType) => {
    setDynamicInputs((prevState) => ({
      ...prevState,
      [optionType]: value,
    }));
    if (optionType === "weblink") {
      // Regex for validating URL
      const urlPattern = new RegExp(
        "^(https?:\\/\\/)?" + // Protocol
          "((([a-zA-Z0-9$\\-_@.&+!*(),]|[a-zA-Z0-9-])+\\.)+[a-zA-Z]{2,6})" + // Domain name
          "(\\:\\d+)?(\\/[-a-zA-Z0-9%_.~+]*)*" + // Port and path
          "(\\?[;&a-zA-Z0-9%_.~+=-]*)?" + // Query string
          "(\\#[-a-zA-Z0-9_]*)?$" // Fragment locator
      );

      // Validate the weblink
      if (urlPattern.test(value)) {
        setError(""); // Clear error message
        setDynamicInputs((prevState) => ({
          ...prevState,
          [optionType]: value,
        }));
        setAnswerObject((prevState) => ({ ...prevState, [optionType]: value }));
      } else {
        setError("Please enter a valid URL.");
      }
    } else {
      // Default handling for other types
      setDynamicInputs((prevState) => ({
        ...prevState,
        [optionType]: value,
      }));
      setAnswerObject((prevState) => ({ ...prevState, [optionType]: value }));
    }
  };

  const renderDynamicInput = (detail) => {
    switch (detail?.option_type) {
      case "attibutes":
        return (
          <Form.Group
            controlId={`attributes_${detail.detail_id}`}
            key={detail.detail_id}
          >
            <Form.Label className="custom-label">Attributes</Form.Label>
            <Form.Control
              type="text"
              as="textarea"
              value={detail.option || ""}
              readOnly
              style={{ backgroundColor: "#BFD7E0" }}
              onDoubleClick={() => handleDoubleClick(detail.option)}
            />
          </Form.Group>
        );
      case "weblink":
        return (
          <Form.Group
            controlId={`weblink_${detail.detail_id}`}
            key={detail.detail_id}
          >
            <Form.Label className="custom-label">{detail?.option}</Form.Label>
            <Form.Control
              type="text"
              as="textarea"
              value={answerObject[detail?.option_type]}
              onDoubleClick={() =>
                handleDoubleClick(answerObject[detail?.option_type])
              }
              readOnly={
                menu === "audit" ||
                (assignedToDetails?.assignedTo?.length > 0 &&
                  !assignedToDetails?.assignedTo?.some(
                    (id) => parseInt(id, 10) === currentUserId
                  )) ||
                (check && edit) ||
                (assignedToDetails?.dueDate &&
                  new Date(assignedToDetails.dueDate).setHours(0, 0, 0, 0) <
                    new Date().setHours(0, 0, 0, 0))
              }
              onChange={(e) =>
                handleDynamicInputChange(
                  detail?.detail_id,
                  e.target.value,
                  detail?.option_type
                )
              }
              style={{ backgroundColor: "#BFD7E0" }}
            />
            {error && <div style={{ color: "red" }}>{error}</div>}
          </Form.Group>
        );
      default:
        return (
          <Form.Group
            controlId={`details_${detail?.detail_id}`}
            key={detail.detail_id}
          >
            <Form.Label className="custom-label">{detail.option}</Form.Label>
            <Form.Control
              as="textarea"
              type="text"
              value={answerObject[detail?.option_type]}
              onDoubleClick={() =>
                handleDoubleClick(answerObject[detail?.option_type])
              }
              readOnly={
                menu === "audit" ||
                (assignedToDetails?.assignedTo?.length > 0 &&
                  !assignedToDetails?.assignedTo?.some(
                    (id) => parseInt(id, 10) === currentUserId
                  )) ||
                (check && edit) ||
                (assignedToDetails?.dueDate &&
                  new Date(assignedToDetails.dueDate).setHours(0, 0, 0, 0) <
                    new Date().setHours(0, 0, 0, 0))
              }
              onChange={(e) =>
                handleDynamicInputChange(
                  detail?.detail_id,
                  e.target.value,
                  detail?.option_type
                )
              }
              style={{ backgroundColor: "#BFD7E0" }}
            />
          </Form.Group>
        );
    }
  };

  return (
    <Form>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        {check &&
          assignedToDetails?.assignedTo?.length > 0 &&
          assignedToDetails.assignedTo.some(
            (id) => parseInt(id, 10) === currentUserId
          ) && (
            <FaEdit
              style={{
                cursor: "pointer",
                marginLeft: "10px",
                height: "20px",
                width: "20px",
                color: edit ? "black" : "#BFD7E0",
              }}
              onClick={handleEditClick}
            />
          )}
      </div>
      <Row>
        {item?.details
          ?.slice()
          .reverse()
          .slice(0, 1)
          .map((detail) => {
            return (
              <Col md={12} style={{ marginTop: "10px" }}>
                {renderDynamicInput(detail)}
              </Col>
            );
          })}
      </Row>
      <Row>
        <Col md={3}>
          <Form.Group controlId="formInput10">
            <Form.Label className="custom-label">Response</Form.Label>
            <div className="select-wrapper">
              <Dropdown onSelect={handleResponseSelect}>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  style={{
                    backgroundColor: "#BFD7E0",
                    color: "black",
                    borderColor: "white",
                    width: "100%",
                  }}
                  readOnly={
                    menu === "audit" ||
                    (assignedToDetails?.assignedTo?.length > 0 &&
                      !assignedToDetails?.assignedTo?.some(
                        (id) => parseInt(id, 10) === currentUserId
                      )) ||
                    (check && edit) ||
                    (assignedToDetails?.dueDate &&
                      new Date(assignedToDetails.dueDate).setHours(0, 0, 0, 0) <
                        new Date().setHours(0, 0, 0, 0))
                  }
                >
                  <span style={{ marginRight: "65%" }}>
                    {response || "Select"}
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item eventKey="Yes">Yes</Dropdown.Item>
                  <Dropdown.Item eventKey="No">No</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Form.Group>
        </Col>

        {!showWeblink && (
          <Row>
            <Col md={5}>
              <Form.Group controlId="formFile">
                <Form.Label className="custom-label">
                  Upload Attachment
                </Form.Label>
                <Form.Control
                  style={{ backgroundColor: "#BFD7E0" }}
                  type="file"
                  onChange={(e) => uploadFile(e)}
                  readOnly={
                    menu === "audit" ||
                    (assignedToDetails?.assignedTo?.length > 0 &&
                      !assignedToDetails?.assignedTo?.some(
                        (id) => parseInt(id, 10) === currentUserId
                      )) ||
                    (check && edit) ||
                    (assignedToDetails?.dueDate &&
                      new Date(assignedToDetails.dueDate).setHours(0, 0, 0, 0) <
                        new Date().setHours(0, 0, 0, 0))
                  }
                />
              </Form.Group>
              <>
                <Form.Group>
                  <Form.Label className="custom-label">
                    Uploaded Files
                  </Form.Label>
                  {proofDocument[0]?.length > 0 &&
                    proofDocument[0].map((url, index) => {
                      console.log("urlurlurl", url);
                      const segments = url?.split("/");
                      const fileName =
                        segments?.length > 1
                          ? segments[segments.length - 2] ===
                            segments[segments.length - 1]
                            ? segments[segments.length - 1] // Handle repeated segment
                            : segments[segments.length - 1] // Regular case
                          : url;

                      return (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 5,
                            borderBottom: "1px solid #ccc",
                            paddingBottom: 5,
                          }}
                        >
                          {/* Download Icon */}
                          <FaFileDownload
                            style={{
                              marginLeft: 10,
                              cursor: "pointer",
                              height: "20px",
                              width: "20px",
                              color: "#3F88A5", // Blue color to indicate it's a link
                            }}
                            onClick={() => handleFileDownload(url)}
                            title="Download File" // Tooltip for better UX
                          />

                          {/* File Name */}
                          <span
                            style={{
                              marginLeft: 10,
                              marginRight: 10,
                              wordBreak: "break-all",
                              flexGrow: 1, // Take available space
                              maxWidth: "70%", // Prevent overflow
                            }}
                            title={fileName} // Full file name tooltip
                          >
                            {fileName}
                          </span>

                          {/* Delete Icon */}
                          <FaTrash
                            style={{
                              cursor: "pointer",
                              height: "20px",
                              width: "20px",
                              color: "#3F88A5",
                              marginRight: 20, // Add margin for spacing
                            }}
                            onClick={() => handleDeleteClick(url)}
                            title="Remove File" // Tooltip for better UX
                          />
                        </div>
                      );
                    })}
                </Form.Group>

                {/* Confirmation Modal */}
                <Modal show={showModal} onHide={cancelDelete} centered>
                  <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Are you sure you want to delete this file?
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={cancelDelete}>
                      Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmDelete}>
                      Delete
                    </Button>
                  </Modal.Footer>
                </Modal>
              </>
            </Col>
          </Row>
        )}
        {!showWeblink && (
          <Col md={12}>
            <Form.Group controlId="formInput14">
              <Form.Label className="custom-label">Note</Form.Label>
              <Form.Control
                style={{ backgroundColor: "#BFD7E0" }}
                type="text"
                as="textarea"
                value={note[0][0]}
                onChange={handleNoteChange}
                onDoubleClick={() => handleDoubleClick(note)}
                readOnly={
                  menu === "audit" ||
                  (assignedToDetails?.assignedTo?.length > 0 &&
                    !assignedToDetails?.assignedTo?.some(
                      (id) => parseInt(id, 10) === currentUserId
                    )) ||
                  (check && edit) ||
                  (assignedToDetails?.dueDate &&
                    new Date(assignedToDetails.dueDate).setHours(0, 0, 0, 0) <
                      new Date().setHours(0, 0, 0, 0))
                }
              />
            </Form.Group>
          </Col>
        )}

        {showWeblink &&
          item?.details
            ?.slice()
            .reverse()
            .slice(1)
            .map((detail) => {
              return (
                <Row key={detail.detail_id}>
                  <Col md={6} style={{ marginTop: "10px" }}>
                    {renderDynamicInput(detail)}
                  </Col>
                </Row>
              );
            })}
        {showWeblink && <></>}
      </Row>
      {showWeblink && (
        <Row>
          <Col md={5}>
            <Form.Group controlId="formFile">
              <Form.Label className="custom-label">
                Upload Attachment
              </Form.Label>
              <Form.Control
                style={{ backgroundColor: "#BFD7E0" }}
                type="file"
                onChange={(e) => uploadFile(e)}
                readOnly={
                  menu === "audit" ||
                  (assignedToDetails?.assignedTo?.length > 0 &&
                    !assignedToDetails?.assignedTo?.some(
                      (id) => parseInt(id, 10) === currentUserId
                    )) ||
                  (assignedToDetails?.dueDate &&
                    new Date(assignedToDetails.dueDate).setHours(0, 0, 0, 0) <
                      new Date().setHours(0, 0, 0, 0))
                }
              />
            </Form.Group>
            <>
              <Form.Group>
                <Form.Label className="custom-label">Uploaded Files</Form.Label>
                {proofDocument[0]?.length > 0 &&
                  proofDocument[0].map((url, index) => {
                    const segments = url.split("/");
                    const fileName =
                      segments.length > 1
                        ? segments[segments.length - 2] ===
                          segments[segments.length - 1]
                          ? segments[segments.length - 1] // Handle repeated segment
                          : segments[segments.length - 1] // Regular case
                        : url;

                    return (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: 5,
                          borderBottom: "1px solid #ccc",
                          paddingBottom: 5,
                        }}
                      >
                        {/* Download Icon */}
                        <FaFileDownload
                          style={{
                            marginLeft: 10,
                            cursor: "pointer",
                            height: "20px",
                            width: "20px",
                            color: "#3F88A5", // Blue color to indicate it's a link
                          }}
                          onClick={() => handleFileDownload(url)}
                          title="Download File" // Tooltip for better UX
                        />

                        {/* File Name */}
                        <span
                          style={{
                            marginLeft: 10,
                            marginRight: 10,
                            wordBreak: "break-all",
                            flexGrow: 1, // Take available space
                            maxWidth: "70%", // Prevent overflow
                          }}
                          title={fileName} // Full file name tooltip
                        >
                          {fileName}
                        </span>

                        {/* Delete Icon */}
                        <FaTrash
                          style={{
                            cursor: "pointer",
                            height: "20px",
                            width: "20px",
                            color: "#3F88A5",
                            marginRight: 20, // Add margin for spacing
                          }}
                          onClick={() => handleDeleteClick(url)}
                          title="Remove File" // Tooltip for better UX
                        />
                      </div>
                    );
                  })}
              </Form.Group>

              {/* Confirmation Modal */}
              <DetailModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                data={showData}
              />
              <Modal show={showModal} onHide={cancelDelete} centered>
                <Modal.Header closeButton>
                  <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to delete this file?
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={cancelDelete}>
                    Cancel
                  </Button>
                  <Button variant="danger" onClick={confirmDelete}>
                    Delete
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          </Col>
          {showNote && (
            <Col md={12}>
              <Form.Group controlId="formInput14">
                <Form.Label className="custom-label">Note</Form.Label>
                <Form.Control
                  style={{ backgroundColor: "#BFD7E0" }}
                  type="text"
                  as="textarea"
                  value={note[0][0]}
                  onChange={handleNoteChange}
                  onDoubleClick={() => handleDoubleClick(note)}
                  readOnly={
                    menu === "audit" ||
                    (assignedToDetails?.assignedTo?.length > 0 &&
                      !assignedToDetails?.assignedTo?.some(
                        (id) => parseInt(id, 10) === currentUserId
                      )) ||
                    (check && edit) ||
                    (assignedToDetails?.dueDate &&
                      new Date(assignedToDetails.dueDate).setHours(0, 0, 0, 0) <
                        new Date().setHours(0, 0, 0, 0))
                  }
                />
              </Form.Group>
            </Col>
          )}
          {menu === "audit" && (
            <Col md={8}>
              <Form.Group controlId="formInput12">
                <Form.Label className="custom-label">Remark</Form.Label>
                <Form.Control
                  style={{ backgroundColor: "#BFD7E0" }}
                  type="text"
                  as="textarea"
                  value={remark}
                  onChange={handleRemarkChange}
                />
              </Form.Group>
            </Col>
          )}
        </Row>
      )}
      <div className="d-flex justify-content-between align-items-center mt-3">
        {/* Placeholder button */}
        <button
          className="btn btn-secondary"
          style={{
            borderColor: "white",
            backgroundColor: "transparent",
            padding: "1%",
            paddingLeft: "1.5%",
            paddingRight: "1.5%",
          }}
        ></button>

        {/* Conditional rendering for buttons */}
        <div
          className="d-flex"
          style={{ width: "30%", justifyContent: "space-between" }}
        >
          {menu === "audit" ? (
            <> 
            {auditorId === currentUserId && (
                <>
                 {status === "ANSWERED" ? (
                    <>
                     <button
                    className="btn"
                    onClick={handleAccept}
              disabled={auditButton}
                    style={{
                      borderColor: "white",
                      backgroundColor: "#3F88A5",
                      padding: "2%",
                      paddingLeft: "10%",
                      paddingRight: "10%",
                    }}
                  >
                    Accept
                  </button>
                  <button
                    className="btn"
                    onClick={handleReject}
                    disabled={!response || auditButton}
                    style={{
                      borderColor: "white",
                      backgroundColor: "#3F88A5",
                      padding: "1%",
                      paddingLeft: "10%",
                      paddingRight: "10%",
                    }}
                  >
                    Reject
                  </button>
                    </>
                  ) : status === "ACCEPTED" || status === "REJECTED" ? (
                    <p>This answer has been audited.</p>
                  ) : null}
                </>
              )}
</>
          ) : (
            assignedToDetails?.assignedTo?.length > 0 &&
            assignedToDetails?.assignedTo?.some(
              (id) => parseInt(id, 10) === currentUserId
            ) && (
              <button
                className="btn btn-primary"
                onClick={handleSubmit}
                disabled={
                  !response && item?.frequency === "CUSTOM" && !selectedPeriod
                }
                style={{
                  borderColor: "white",
                  backgroundColor: "#3F88A5",
                  padding: "2%",
                  paddingLeft: "10%",
                  paddingRight: "10%",
                }}
              >
                Submit
              </button>
            )
          )}
        </div>
      </div>
    </Form>
  );
};

export default YesNoComponent;
