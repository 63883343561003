import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./accordioncomponent.css";

import "react-datepicker/dist/react-datepicker.css";

import MainAccordComponent from "./MainAccordComponent";
import { apiCall } from "../../../_services/apiCall";
import config from "../../../config/config.json";
import { useLocation } from "react-router-dom";
const AccordionComponent = ({
  getAuditListing,
  sourceData,
  currentUserId,
  menu,
  financeObject,
  moduleData,
  financialYear,
  startingMonth,
  modId,
  assignedTo,
  correctModuleId,
}) => {
  const location = useLocation();
  const moduleId =
    menu === "audit"
      ? localStorage.getItem("auditModuleId")
      : localStorage.getItem("moduleId");

  const [activeIndex, setActiveIndex] = useState(null);
  const [auditedDate, setAuditedDate] = useState(new Date());
  const [selectedPeriod, setSelectedPeriod] = useState();
  const [savedAnswers, setSavedAnswers] = useState([{}]);
  const [userData,setUserData]=useState([])

  const [startDate, setStartDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const [applicable, setApplicable] = useState(true);

  const handleDateChange = (date) => {
    setStartDate(date);
    setIsOpen(false); // Close the calendar after selecting a date
  };

  const handlePeriodSelect = (key) => {
    setSelectedPeriod(key);
  };

  const handleCalendarOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    setActiveIndex(null);
  }, [moduleData]);

  const getAllUser = async () => {
  
      const { isSuccess, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}getAllUser`,
        {},
        {},
        "GET"
      );
      if(isSuccess){
          console.log("data is",data.data)
          setUserData(data.data)
      }
     
    
  };

  const correctModuleIdValue =
    Array.isArray(correctModuleId) && correctModuleId.length === 0
      ? menu === "audit"
        ? localStorage.getItem("auditModuleId")
        : localStorage.getItem("moduleId")
      : correctModuleId;

  const moduleIdToUse =
    correctModuleIdValue ||
    (menu === "audit"
      ? localStorage.getItem("auditModuleId")
      : localStorage.getItem("moduleId"));
  console.log("coorectmoduleid", moduleIdToUse);

  const getReportingQuestionAnswer = async () => {
    if (moduleIdToUse) {
      const { isSuccess, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}getReportingQuestionAnswer`,
        {},
        { moduleId: moduleIdToUse },
        "GET"
      );
      if (isSuccess) {
        setSavedAnswers(data.answers);
        console.log("rum", data.answers);
      }
    }
  };

  useEffect(() => {}, [financeObject]);
  useEffect(()=>{
    getAllUser()
  },[])

  useEffect(() => {
    if (moduleIdToUse) {
      getReportingQuestionAnswer();


    }
  }, [moduleIdToUse]);

  return (
    <div
      className="container w-100 my-3"
      style={{ background: "transparent", padding: "0%" }}
    >
      <div className="accordion" id="accordionExample">
      {moduleData && moduleData.length > 0 ? (
  moduleData.map((item, index) => (
    <MainAccordComponent
      key={index} // Add a key to help React identify items
      getAuditListing={getAuditListing}
      sourceData={sourceData}
      currentUserId={currentUserId}
      menu={menu}
      assignedTo={assignedTo}
      savedAnswers={savedAnswers}
      moduleId={correctModuleId}
      userData={userData}
    financeObject={financeObject}
      applicable={applicable}
      item={item}
      index={index}
      activeIndex={activeIndex}
      handleAccordionClick={handleAccordionClick}
      startingMonth={startingMonth}
      financialYear={financialYear}
      handlePeriodSelect={handlePeriodSelect}
      selectedPeriod={selectedPeriod}
      handleDateChange={handleDateChange}
      handleCalendarOpen={handleCalendarOpen}
      isOpen={isOpen}
      setApplicable={setApplicable}
      startDate={startDate}
    />
  ))
) : (
  <div>No data available</div> // Replace with your preferred message or component
)}
      </div>
    </div>
  );
};

export default AccordionComponent;
