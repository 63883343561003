import React from "react";
import Chart from "react-apexcharts";

const GreenProcurementRate = () => {
  const options = {
    chart: {
      type: "area",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: ["Q1", "Q2", "Q3", "Q4"],
    },
    yaxis: {
      max: 100,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
      },
    },
    title: {
      text: "Green Procurement Rate",
      align: "left",
      style: {
        fontSize: "18px",
        fontWeight: "bold",
      },
    },
    subtitle: {
      text: "Percentage of suppliers or products sourced according to sustainability criteria",
      align: "left",
      margin: 10,
      offsetX: 10,
      style: {
        fontSize: "14px",
      },
    },
    grid: {
      borderColor: "#e7e7e7",
    },
  };

  const series = [
    {
      name: "Green Procurement Rate",
      data: [85, 40, 25, 10],
    },
  ];

  return (
    <div className="container" style={{ padding: "20px", backgroundColor: "#f4f6f8", borderRadius: "10px" }}>
      <Chart options={options} series={series} type="area" height={350} />
    </div>
  );
};

export default GreenProcurementRate;
