import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const HealthSafetyMeasuresTraining = () => {
  const [selectedCategory, setSelectedCategory] = useState('Employees');

  const data = {
    Employees: [80, 60, 20], // Male, Female, Others
    Workers: [60, 50, 10],
    EmployeesNotUnderTraining: [100, 80, 40],
    WorkersNotUnderTraining: [120, 90, 30],
  };

  const series = [
    {
      name: 'Male',
      data: [data[selectedCategory][0]],
      color: '#3F88A5',
    },
    {
      name: 'Female',
      data: [data[selectedCategory][1]],
      color: '#D94F4F',
    },
    {
      name: 'Others',
      data: [data[selectedCategory][2]],
      color: '#A9D9DE',
    },
  ];

  const options = {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '70%',
      },
    },
    xaxis: {
      categories: [selectedCategory],
      labels: {
        formatter: (val) => `${val}`,
      },
    },
    yaxis: {
      show: false,
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
      labels: {
        useSeriesColors: true,
      },
    },
    grid: {
      show: true,
      borderColor: '#D0D0D0',
      strokeDashArray: 5,
    },
  };

  return (
    <div className='container'>
      <div className="radio-buttons">
        <label>
          <input
            type="radio"
            name="category"
            value="Employees"
            checked={selectedCategory === 'Employees'}
            onChange={() => setSelectedCategory('Employees')}
          />
          Employees
        </label>
        <label>
          <input
            type="radio"
            name="category"
            value="Workers"
            checked={selectedCategory === 'Workers'}
            onChange={() => setSelectedCategory('Workers')}
          />
          Workers
        </label>
        <label>
          <input
            type="radio"
            name="category"
            value="EmployeesNotUnderTraining"
            checked={selectedCategory === 'EmployeesNotUnderTraining'}
            onChange={() => setSelectedCategory('EmployeesNotUnderTraining')}
          />
          Employees Not Under Training
        </label>
        <label>
          <input
            type="radio"
            name="category"
            value="WorkersNotUnderTraining"
            checked={selectedCategory === 'WorkersNotUnderTraining'}
            onChange={() => setSelectedCategory('WorkersNotUnderTraining')}
          />
          Workers Not Under Training
        </label>
      </div>
      
      <ReactApexChart options={options} series={series} type="bar" height={350} />

      <div className="legend">
        <div>
          <span style={{ backgroundColor: '#3F88A5', borderRadius: '50%', display: 'inline-block', width: '10px', height: '10px', marginRight: '5px' }}></span>
          Male
        </div>
        <div>
          <span style={{ backgroundColor: '#D94F4F', borderRadius: '50%', display: 'inline-block', width: '10px', height: '10px', marginRight: '5px' }}></span>
          Female
        </div>
        <div>
          <span style={{ backgroundColor: '#A9D9DE', borderRadius: '50%', display: 'inline-block', width: '10px', height: '10px', marginRight: '5px' }}></span>
          Others
        </div>
      </div>
    </div>
  );
};

export default HealthSafetyMeasuresTraining;
