import React from "react";
import "./Overview.css"; // CSS file for styling
const Overview = () => {
  const data = [
    {
      title: "Non-Renewable Energy",
      value: "20.6%",
      color: "#6EC1E4",
      progress: 20.6,
    },
    {
      title: "Scope 1 Emission",
      value: "3.7%",
      color: "#A2D9CE",
      progress: 3.7,
    },
    {
      title: "Scope 2 Emission",
      value: "96.3%",
      color: "#34495E",
      progress: 96.3,
    },
    {
      title: "Water Consuption",
      value: "100%",
      color: "#5DADE2",
      progress: 100,
    },
    { title: "Waste Disposed", value: "0%", color: "#2ECC71", progress: 0 },
  ];
  return (
    <div className="dashboard-overview">
      <div className="header">
        <div className="title" style={{ fontWeight: 600 }}>
          Overview Of All Branches
        </div>
      </div>
      <div className="metrics-container">
        {data.map((item, index) => (
          <div className="metric-card" key={index}>
            <div style={{
              width:"100%",
              display:"flex",
              justifyContent:"space-between"
            }}>
            <div className="card-title">{item.title}</div>

            <div className="percentage-value">{item.value}</div>
            </div>
            <div className="progress-bar-container">
              <div
                className="progress-bar"
                style={{
                  width: `${item.progress}%`,
                  backgroundColor: item.color,
                }}
              ></div>
            </div>
            
          </div>
        ))}
      </div>
      <div className="legends-container">
        {data.map((item, index) => (
          <div className="legend-item" key={index}>
            <span
              className="legend-color"
              style={{ backgroundColor: item.color }}
            ></span>
            {item.title}
          </div>
        ))}
      </div>
    </div>
  );
};
export default Overview;
