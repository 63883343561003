import React, { useState } from "react";
import Chart from "react-apexcharts";

const HumanRightsTraining = () => {
  const [selectedOption, setSelectedOption] = useState("Employees"); // Default option

  // Define data for each radio button option
  const dataOptions = {
    Employees: [
      { name: "Male", data: [80, 120, 150, 200] },
      { name: "Female", data: [60, 100, 130, 180] },
      { name: "Others", data: [30, 60, 90, 120] },
    ],
    PermanentEmployeesCovered: [
      { name: "Male", data: [100, 140, 170, 220] },
      { name: "Female", data: [80, 110, 140, 190] },
      { name: "Others", data: [50, 80, 110, 150] },
    ],
    OtherThanPermanentEmployeesCovered: [
      { name: "Male", data: [90, 130, 160, 210] },
      { name: "Female", data: [70, 100, 130, 170] },
      { name: "Others", data: [40, 70, 100, 130] },
    ],
    Workers: [
      { name: "Male", data: [60, 100, 130, 160] },
      { name: "Female", data: [40, 80, 110, 140] },
      { name: "Others", data: [20, 50, 80, 110] },
    ],
    EmployeesNotCovered: [
      { name: "Male", data: [70, 110, 140, 180] },
      { name: "Female", data: [50, 90, 120, 150] },
      { name: "Others", data: [30, 60, 90, 120] },
    ],
  };

  const chartOptions = {
    chart: {
      type: "area",
      height: 350,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: ["Q1", "Q2", "Q3", "Q4"],
    },
    legend: {
      position: "top",
    },
    colors: ["#3F88A5", "#E57373", "#4FC3F7"], // Custom colors for the chart
  };

  return (
    <div className="container">
      <h3>Human Rights Training</h3>
      <div className="radio-buttons">
        {["Employees", "PermanentEmployeesCovered", "OtherThanPermanentEmployeesCovered", "Workers", "EmployeesNotCovered"].map((option) => (
          <label key={option}>
            <input
              type="radio"
              value={option}
              checked={selectedOption === option}
              onChange={(e) => setSelectedOption(e.target.value)}
            />
            {option.replace(/([A-Z])/g, ' $1').trim()} {/* Formatting option names */}
          </label>
        ))}
      </div>

      <div className="chart-container">
        <Chart
          options={chartOptions}
          series={dataOptions[selectedOption]}
          type="area"
          height="350"
        />
      </div>
    </div>
  );
};

export default HumanRightsTraining;
