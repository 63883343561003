import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Modal } from "react-bootstrap";
import { BarChart, ColumnChart, RadioBarChart } from "./Chart";
import { RadioBarChartData, ColumnChartData, BarChartData } from "./chartData";
import config from "../../../../config/config.json";
import { apiCall } from "../../../../_services/apiCall";

const FrameworkComponent = ({fromDate,toDate,financialYearId}) => {
  const [filter, setFilter] = useState(false);
  const [radioBarGraphData, setRadioBarGraphData] = useState([]);
  const [selectedFramework, setSelectedFramework] = useState(null);
  const [Framework, setFramework] = useState([]);
  const [frameworkOptions, setFrameworkOptions] = useState([]);
  const [chartType, setChartType] = useState("");
  const handleFilterClose = () => setFilter(false);
  const [selectedOption, setSelectedOption] = useState('BRSR'); // Initial selected option

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const myDisclosureProgress = async () => {

    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}frameworkProgress`,
      {},
      {fromDate:fromDate,
        toDate: toDate,
        financialYearId:financialYearId
      },
      "GET"
    );
    if (isSuccess) {
      let frameworkOptions = data?.data?.frameworkOptions;
      setFrameworkOptions(frameworkOptions);

      const color = ["#11546F", "#3ABEC7", "#3F88A5", "#B80000"]
      const disableToolbar = true;
      const GraphData = await RadioBarChartData(data?.data?.categories, data?.data?.series, color, data?.data?.questionsIds, disableToolbar);
      setRadioBarGraphData(GraphData);
      const filter = data?.data?.filter;
      const c=data
      console.log("bbbbbbbbbb",GraphData, c)
      setSelectedFramework(filter?.frameworkIds);
    }
  };

  const handleApplyFilter = async () => {
    handleFilterClose();
  };


  useEffect(() => {
    if(financialYearId)
    myDisclosureProgress();
  }, [fromDate,toDate,financialYearId]);
  return (
    <>
      <Col md={12} style={{height:"100%"}}>
        <div className="bg-white framework" style={{height:"100%"}}>

          <div className="frameworkHeader">
            My Disclosure Progress
          </div>
          <div className="p-1">
            <div className="radioflex">
             
           
              {frameworkOptions && frameworkOptions?.length > 1 && 
              (
                <div className="grouping">
                  <input
                    type="radio"
                    id="checkbox-All"
                    name="radioGroup"
                    value="All"
                    checked={selectedOption === 'All'}
                    onChange={handleRadioChange}
                  />
                  <label htmlFor="checkbox-All" className="groupingtext">All</label>
                </div>
              )}
            </div>
            <div className="main_text">
              {frameworkOptions && frameworkOptions?.length && selectedOption === "BRSR" && radioBarGraphData ? (
                <RadioBarChart chartData={radioBarGraphData} />
              ) : (
                selectedOption
              )}
            </div>
          </div>

        </div>
      </Col>
      <Modal size="md" show={filter} onHide={handleFilterClose}>
        <Modal.Header closeButton>
          <Form.Label className="align-items-center m-0">
            Framework Progress Filter
          </Form.Label>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="formStatusType">
            <Form.Label>Select Framework Name</Form.Label>
            <Form.Select
              aria-label="Select Framework"
              className="w-100"
              value={selectedFramework}
              onChange={(e) => {
                const selectedValue = e.target.value;
                setSelectedFramework(selectedValue);
              }}
            >

              {Framework?.map((framework) => (
                <option key={framework.id} value={framework.title}>
                  {framework?.title}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

        
        </Modal.Body>
        <Modal.Footer>
          <button className="new_button_style" onClick={handleApplyFilter}>
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FrameworkComponent;
