import React, { useState } from "react";
import Chart from "react-apexcharts";

const LeadershipRolesChart = () => {
  const [selectedCriteria, setSelectedCriteria] = useState("Time");
  const [selectedOption, setSelectedOption] = useState(null);
  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: { type: "bar", stacked: false },
      plotOptions: { bar: { horizontal: false } },
      dataLabels: { enabled: false },
      stroke: { width: [0, 0, 3, 2] }, // Set stroke for line and area charts
      xaxis: {
        categories: ["Q1", "Q2", "Q3", "Q4"],
      },
      colors: ["#E91E63", "#00ACC1", "#8BC34A", "#FF9800"],
    },
  });

  const dataSets = {
    Time: {
      Q1: [
        { name: "Female", type: "bar", data: [150, 200, 250, 300] },
        { name: "Minorities", type: "bar", data: [80, 120, 180, 220] },
        { name: "Board Of Directors", type: "area", data: [50, 60, 70, 90] }, // Area chart
        { name: "Other Roles", type: "line", data: [30, 50, 70, 80] }, // Line chart
      ],
      Q2: [
        { name: "Female", type: "bar", data: [170, 210, 260, 310] },
        { name: "Minorities", type: "bar", data: [90, 130, 190, 230] },
        { name: "Board Of Directors", type: "area", data: [60, 70, 80, 100] },
        { name: "Other Roles", type: "line", data: [40, 60, 80, 90] },
      ],
      // Add more time-based data
    },
    Location: {
      Location1: [
        { name: "Female", type: "bar", data: [200, 220, 240, 260] },
        { name: "Minorities", type: "bar", data: [100, 130, 160, 190] },
        { name: "Board Of Directors", type: "area", data: [70, 80, 90, 110] },
        { name: "Other Roles", type: "line", data: [50, 70, 90, 110] },
      ],
      Location2: [
        { name: "Female", type: "bar", data: [180, 200, 220, 240] },
        { name: "Minorities", type: "bar", data: [90, 120, 150, 180] },
        { name: "Board Of Directors", type: "area", data: [60, 70, 80, 100] },
        { name: "Other Roles", type: "line", data: [40, 60, 80, 100] },
      ],
      // Add more location-based data
    },
  };

  const handleCriteriaChange = (e) => {
    setSelectedCriteria(e.target.value);
    setSelectedOption(null);
  };

  const handleOptionChange = (e) => {
    const option = e.target.value;
    setSelectedOption(option);
    setChartData({
      ...chartData,
      series: dataSets[selectedCriteria][option],
    });
  };

  return (
    <div className="container">
      <h4>Time and Location</h4>
      <div>
        <label>
          <input
            type="radio"
            name="criteria"
            value="Time"
            checked={selectedCriteria === "Time"}
            onChange={handleCriteriaChange}
          />
          Time
        </label>
        <label>
          <input
            type="radio"
            name="criteria"
            value="Location"
            checked={selectedCriteria === "Location"}
            onChange={handleCriteriaChange}
          />
          Location
        </label>
      </div>

      {selectedCriteria === "Time" && (
        <div>
          <div>
            <label>
              <input
                type="radio"
                name="option"
                value="Q1"
                checked={selectedOption === "Q1"}
                onChange={handleOptionChange}
              />
              Q1
            </label>
            <label>
              <input
                type="radio"
                name="option"
                value="Q2"
                checked={selectedOption === "Q2"}
                onChange={handleOptionChange}
              />
              Q2
            </label>
            {/* Add more time options */}
          </div>
        </div>
      )}

      {selectedCriteria === "Location" && (
        <div>
          <div>
            <label>
              <input
                type="radio"
                name="option"
                value="Location1"
                checked={selectedOption === "Location1"}
                onChange={handleOptionChange}
              />
              Location 1
            </label>
            <label>
              <input
                type="radio"
                name="option"
                value="Location2"
                checked={selectedOption === "Location2"}
                onChange={handleOptionChange}
              />
              Location 2
            </label>
            {/* Add more location options */}
          </div>
        </div>
      )}

      {selectedOption && (
        <div>
          <Chart
            options={chartData.options}
            series={chartData.series}
            type="line"
            height={300}
          />
        </div>
      )}
    </div>
  );
};

export default LeadershipRolesChart;
