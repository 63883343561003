import React from "react";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import due from "../../img/Due.svg";
import updated from "../../img/updated.svg";
import done from "../../img/shape.svg";
import defaulted from "../../img/Defaulted.svg";
import { useState } from "react";
import { useEffect } from "react";
import TopComponentWaste from "./TopComponentWaste";
import WasteGenerated from "./WasteGenerated";
import WasteRecovered from "./WasteRecovered";
import WasteDisposed from "./WasteDisposed";
import WasteComparison from "./WasteComparison";
import TotalWasteGeneratedByLoc from "./TotalWasteGeneratedByLoc";
import WasteDisposedMulti from "./WwasteDisposedMultiLoc";
import WasteRecoveredMulti from "./WasteRecoveredMulti";
import WasteRecTarget from "./WasteRecTraget";
import WasteCostAnalysis from "./WasteCostAnalysis";

const Waste = ({  locationOption,
  timePeriods,
  financialYearId,
  graphData,}) => {
  const location=2;
  const [lastWeekAcitivities, setLastWeekAcitivities] = useState();
  const icons = {
    done: done,
    updated: updated,
    due: due,
    pending: defaulted,
  };

  const lastWeekActivity = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}lastWeekActivity`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      const data = {
        "Total Waste": {
          "number": '2354',
          "questionId": [ ]
        },
        "Waste Recovered": {
          "number": '123',
       "questionId": []
        },
        "Waste Disposed": {
          "number": '57',
          "questionId": []
        },
  
        "message": "Good Evening, Sunil Kumar"
      }
      setLastWeekAcitivities(data);
    }
  };

  useEffect(() => {
    lastWeekActivity();
  }, []);
  return (
    <div className="progress-container">
      <div className="topcompo">
        {lastWeekAcitivities && (
          <TopComponentWaste
            lastWeekAcitivities={lastWeekAcitivities}
            icons={icons}
          />
        )}
      </div>
     
      {location >= 2 &&
      <div className="d-flex flex-column flex-space-between">
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "70vh", marginBottom: "3%" }}
      >
      <TotalWasteGeneratedByLoc locationOption = {locationOption} timePeriods = {timePeriods} financialYearId = {financialYearId} graphData = {graphData} />
      </div>
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "60vh", marginBottom: "3%" }}
      >
        {/* <div
          className="firsthalfprogressenergy"
          
        > */}
          <WasteDisposedMulti locationOption = {locationOption} timePeriods = {timePeriods} financialYearId = {financialYearId} graphData = {graphData}/>

        {/* </div> */}
        {/* <div className="secondhalfprogress" >
        <WasteRecoveredMulti/>
        </div> */}
      </div>
      {/* <div className="d-flex flex-row flex-space-between"
      style={
        {
          height:"70vh"
        }
      }>
       <div
          className="firsthalfprogressenergy"
          
        >
          <WasteRecTarget/>

        </div>
        <div className="secondhalfprogress" >
          <WasteCostAnalysis/>
        </div>

      </div> */}
    </div>
      
      }
    </div>
  );
};

export default Waste;
