import React from "react";
import { Table, Modal, Button } from "react-bootstrap";
import "./TabularQuestionType.css";
import { useState } from "react";

const TabularQuestionType = ({
  title,
  answer,
  item,
  question_detail,
  note,
  combinedAnswers,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [fullText, setFullText] = useState("");

  const handleDoubleClick = (text) => {
    setFullText(text);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  console.log("itemxs", item);

  const rows = question_detail?.filter(
    (detail) => detail.option_type === "row"
  );
  const columns = question_detail?.filter(
    (detail) => detail.option_type === "column"
  );

  const FullTextModal = ({ show, handleClose, fullText }) => (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Full Text</Modal.Title>
      </Modal.Header>
      <Modal.Body>{fullText}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const wrapTextWithLineBreaks = (text, wordsPerLine) => {
    if (!text) return "";
    const words = text.split(" ");
    const lines = [];
    for (let i = 0; i < words.length; i += wordsPerLine) {
      lines.push(words.slice(i, i + wordsPerLine).join(" "));
    }
    return lines.join("<br />");
  };

  const areAllValuesSame = (rowIndex) => {
    if (
      !combinedAnswers ||
      !combinedAnswers[0] ||
      !combinedAnswers[0].answer ||
      !combinedAnswers[0].answer[rowIndex]
    ) {
      return false;
    }

    const firstValue = combinedAnswers[0].answer[rowIndex][0];
    return combinedAnswers[0].answer[rowIndex].every(
      (value) => value === firstValue
    );
  };

  const addLineBreaks = (text, maxLength) => {
    if (!text) return "";
    const regex = new RegExp(`(.{1,${maxLength}})`, "g");
    return text.match(regex).join("<br />");
  };
  return (
    <div
      className="tabular-question w-100"
      style={{ width: "100%", maxWidth: "100%" }}
    >
      <Table
        bordered
        hover
        responsive="sm"
        className="custom-table"
        style={{ width: "100%", maxWidth: "100%" }}
      >
        <thead
          style={{
            borderRight: "none",
            borderLeft: "none",
            width: "100%",
            maxWidth: "100%",
          }}
        >
          <tr style={{ borderRight: "none", borderLeft: "none" }}>
            {/* Empty header cell for row labels */}
            <th
              className="header-celll"
              style={{
                borderRight: "none",
                borderLeft: "none",
                textAlign: "left",
              }}
            >
              <div>#</div>
            </th>
            {columns?.map((column) => (
              <th
                key={column?.id}
                style={{
                  borderRight: "none",
                  borderLeft: "none",
                  textAlign: "left",
                }}
                className="header-celll"
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: wrapTextWithLineBreaks(column?.option, 5),
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                ></div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody
          style={{
            borderRight: "none",
            borderLeft: "none",
            width: "100%",
            maxWidth: "100%",
          }}
        >
          {/* {rows?.map((row, rowIndex) => (
            <tr
              key={row?.id}
              style={{ borderRight: "none", borderLeft: "none", }}
            >
              <td
                className="row-label"
                style={{ borderRight: "1px solid #83BBD5", borderLeft: "none", }}
              >
                {row?.option}
              </td>
              {columns?.map((column, colIndex) => (
               <td
               style={{ borderRight: "1px solid #83BBD5", borderLeft: "none" }}
               key={column?.id}
               className="data-cell"
             >
               {combinedAnswers !== "No Combined" &&
               combinedAnswers[0]?.answer[rowIndex] &&
               combinedAnswers[0]?.answer[rowIndex][colIndex] !== undefined
                 ? combinedAnswers[0]?.answer[rowIndex][colIndex]
                 : ""}
             </td>
              ))}
            </tr>
          ))} */}
          {rows.map((row, rowIndex) => (
            <tr
              key={row?.id}
              style={{ borderRight: "none", borderLeft: "none" }}
            >
              <td
                className="row-label"
                style={{ borderRight: "1px solid #83BBD5", borderLeft: "none" }}
              >
                {row?.option}
              </td>
              {columns?.map((column, colIndex) => {
                const cellText =
                  combinedAnswers !== "No Combined" &&
                  combinedAnswers[0]?.answer[rowIndex] &&
                  combinedAnswers[0]?.answer[rowIndex][colIndex] !== undefined
                    ? combinedAnswers[0]?.answer[rowIndex][colIndex]
                    : "";

                const displayText =
                  cellText.length > 100
                    ? cellText.substring(0, 100) + "..."
                    : cellText;

                return (
                  <td
                    key={column?.id}
                    style={{
                      borderRight: "1px solid #83BBD5",
                      borderLeft: "none",
                      cursor: "pointer",
                    }}
                    className="data-cell"
                    onDoubleClick={() => handleDoubleClick(cellText)}
                  >
                    {displayText}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </Table>
      {note && (
        <>
          <div
            style={{
              color: "black",
              fontSize: "14px",
              fontFamily: "Open Sans",
              fontWeight: "800",
              wordWrap: "break-word",
            }}
          >
            {" "}
            Note{" "}
          </div>

          <div
            style={{
              color: "#3F88A5",
              fontSize: "10px",
              fontFamily: "Open Sans",
              marginBottom: "10px",
              fontWeight: "600",
              wordWrap: "break-word",
            }}
          >
            {note}
          </div>
        </>
      )}
      <FullTextModal
        show={showModal}
        handleClose={handleClose}
        fullText={fullText}
      />
    </div>
  );
};

export default TabularQuestionType;
