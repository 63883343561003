import React, { useEffect } from "react";
import { Form, Row, Col, Image } from "react-bootstrap";
import FrequencyComponent from "./FrequencyComponent";
import DatePicker from "react-datepicker";
import QualitativeComponent from "./QuestionsComponent/QualitativeComponent";
import TabularComponent from "./QuestionsComponent/TabularComponent";
import YesNoComponent from "./QuestionsComponent/YesNoComponent";
import TrendsComponent from "./QuestionsComponent/TrendsComponent";
import { apiCall } from "../../../_services/apiCall";
import config from "../../../config/config.json";
import { useState } from "react";
import { FaHourglassHalf, FaCheck, FaTimes } from "react-icons/fa";
import reject from "../../../img/rejected.png";
import accept from "../../../img/accept.png";
import complain from "../../../img/complain.png";

import swal from "sweetalert";
import StatusWithTooltip from "./StatusWithToolTip";

const MainAccordComponent = ({
  getAuditListing,
  currentUserId,
  userData,
  menu,
  assignedTo,
  savedAnswers,
  sourceData,
  financeObject,
  applicable,
  activeIndex,
  item,
  index,
  handleAccordionClick,
  startingMonth,
  handlePeriodSelect,
  selectedPeriod,
  financialYear,
  startDate,
  setStartDate,
  setApplicable,
}) => {
  const [mappedUserData, setMappedUserData] = useState([]);

  useEffect(() => {
    const remarkIds = item?.matchingAuditors?.flatMap(
      (auditor) => auditor?.remark?.map((r) => r.id) || []
    );

    if (userData && remarkIds?.length > 0) {
      const filteredUserData = userData.filter((user) =>
        remarkIds.includes(user.id)
      );

      // Now you have the filtered userData
      console.log(filteredUserData);

      // You can do something with this filteredUserData, like setting it in state
      setMappedUserData(filteredUserData); // Assuming setMappedUserData is a state setter
    }
  }, [userData]);

  const StatusIcon = ({ status }) => {
    let icon;
    let color;

    switch (status) {
      case "ANSWERED":
        icon = <Image src={complain} />;
        color = "yellow";
        break;
      case "ACCEPTED":
        icon = <Image src={accept} />;
        color = "#43AC70";
        break;
      case "REJECTED":
        icon = <Image src={reject} />;
        color = "#F2684A";
        break;
      default:
        icon = null;
    }

    return (
      <div
        style={{
          color,
          fontSize: "10px",
          height: "30px",
          width: "40px",
          display: "flex",
          marginLeft: "125%",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        {icon}
      </div>
    );
  };
  const [fromDate, setFromDate] = useState(null);

  const [toDate, setToDate] = useState(null);
  const [sourceChange, setSourceChange] = useState(null);
  const [singleItem, setSingleItem] = useState(null);
  const [auditorId, setAuditorId] = useState();
  const [remark, setRemark] = useState("");
  const [note, setNote] = useState([[]]);
  const [auditButton, setAuditButton] = useState(false);
  const assignedToDetails = assignedTo?.find((data) =>
    menu === "audit"
      ? data.questionId === item.question.questionId
      : data.questionId === item.questionId
  );
  console.log("assigned", assignedToDetails);
  useEffect(() => {
    if (item) {
      if (menu === "audit") {
        setAuditorId(item.auditorId.auditerId);
      } else {
        setAuditorId();
      }
    }
  }, [item]);
  const [selectedValue, setSelectedValue] = useState();

  const initializeAnswer = () => {
    if (item && item.questionId && savedAnswers && savedAnswers.length) {
      // const matchedAnswer = savedAnswers.find(
      //   (savedAnswer) => savedAnswer.questionId == item.questionId
      // );
      let matchedAnswer;
      if (item?.frequency == "CUSTOM") {
        matchedAnswer = savedAnswers.find(
          (savedAnswer) =>
            savedAnswer.questionId == item.questionId &&
            savedAnswer.toDate == toDate &&
            savedAnswer.fromDate == fromDate
        );
      } else {
        matchedAnswer = savedAnswers.find(
          (savedAnswer) => savedAnswer.questionId == item.questionId
        );
      }
      if (matchedAnswer) {
        setNote((prevState) => {
          // Check if matchedAnswer.note is a string
          if (typeof matchedAnswer.note === "string") {
            return [[matchedAnswer.note]]; // Wrap the string in a 2D array
          }

          // Check if matchedAnswer.note is a 2D array
          if (
            Array.isArray(matchedAnswer.note) &&
            Array.isArray(matchedAnswer.note[0])
          ) {
            return matchedAnswer.note; // Use it as is
          }

          // If matchedAnswer.note is not a string or a 2D array, return an empty 2D array
          return [[]];
        });

        setAnswer((prevState) => ({
          ...prevState,
          note: matchedAnswer?.note || [[]],
        }));
      } else {
        setNote([[]]);
      }
    }
  };
  const handleRemarkChange = (e) => {
    const newRemark = e.target.value;
    setRemark(newRemark);
  };

  useEffect(() => {
    if (savedAnswers) {
      initializeAnswer();
      if (menu === "audit") {
        let matchedAnswer = savedAnswers.find(
          (savedAnswer) => savedAnswer.questionId == item.question.questionId
        );
        if (matchedAnswer) {
          setStatus(matchedAnswer?.status);
        }
      } else {
        let matchedAnswer = savedAnswers.find(
          (savedAnswer) => savedAnswer.questionId == item.questionId
        );
        if (matchedAnswer) {
          setStatus(matchedAnswer?.status);
        }
      }
    }
  }, [savedAnswers]);

  useEffect(() => {
    if (assignedToDetails?.assignedToDetails?.length > 0) {
      const assignedDetail = assignedToDetails.assignedToDetails.find(
        (detail) => parseInt(detail.id, 10) === currentUserId
      );
      if (assignedDetail) {
        setSelectedValue(assignedDetail.first_name);
      }
    }
  }, [assignedToDetails, currentUserId]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  useEffect(() => {
    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      financialYearId: financeObject,
    }));
  }, [financeObject]);
  const [answer, setAnswer] = useState({
    financialYearId: financeObject,
    questionId: item?.questionId,
    sourceId: null,
    fromDate: "",
    moduleId: item?.moduleId,
    toDate: "",
    notApplicable: null,
    answer: "",
    proofDocument: [],
    note: [[]],
    questionType: item?.questionType,
    frequency: item?.frequency,
  });

  let matchedAnswer = savedAnswers.find(
    (savedAnswer) => savedAnswer.questionId == item.questionId
  );

  const [status, setStatus] = useState("");

  const handleApplicableChange = (value) => {
    setApplicable(value);
    // Assuming 'answer' state is managed elsewhere and available
    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      notApplicable: !value, // Set 'notApplicable' based on the inverse of 'applicable'
    }));
  };

  const handleAccept = async () => {
    console.log("this is item", item);
    const { isSuccess, error, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}validateAnswers`,
      {},
      {
        questionId: item.question.questionId,
        answerId: item?.answer.id,
        questionType: item.question.questionType,
        remark: remark,
        validation: "ACCEPTED",
        financialYearId: financeObject,
      },
      "POST"
    );

    if (isSuccess) {
      setAuditButton(true);
      getAuditListing();
    }

    if (error) {
      swal({
        icon: "error",
        title: data.message,
        timer: 1000,
      });
    }
  };

  const handleReject = async () => {
    if (remark) {
      const { isSuccess, error, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}validateAnswers`,
        {},
        {
          questionId: item?.question?.questionId,
          answerId: item?.question.answer.id,
          questionType: item?.question?.questionType,
          remark: remark,
          validation: "REJECTED",
          financialYearId: financeObject,
        },
        "POST"
      );

      if (isSuccess) {
        swal({
          icon: "success",
          title: data.message,
          timer: 1000,
        });
      }

      if (error) {
        swal({
          icon: "error",
          title: data.message,
          timer: 1000,
        });
      }
    } else {
      swal({
        icon: "error",
        title: "Please enter remark",
        timer: 1000,
      });
    }
  };

  const handleNoteChange = (e) => {
    const newNote = e.target.value;
    setNote([[newNote]]);
    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      note: [[newNote]],
    }));
  };

  const handleSubmit = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}saveAnswerReportingQuestion`,
      {},
      {
        ...answer,
        financialYearId: financeObject,

        //  answer:answer,
      },
      "POST"
    );

    if (isSuccess) {
    }
  };

  const handleApplicableCheck = (items) => {
    if (menu === "audit") {
      let matchedAnswer = savedAnswers.find(
        (savedAnswer) => savedAnswer.questionId == items.question.questionId
      );

      if (matchedAnswer?.notApplicable) {
        setApplicable(!matchedAnswer.notApplicable);
      } else {
        setApplicable(true);
      }
    } else {
      let matchedAnswer = savedAnswers.find(
        (savedAnswer) => savedAnswer.questionId == items.questionId
      );

      if (matchedAnswer?.notApplicable) {
        setApplicable(!matchedAnswer.notApplicable);
      } else {
        setApplicable(true);
      }
    }
  };

  const handleTitle = (item) => {
    setSingleItem(item);
  };

  const getRemarks = (matchingAuditors, remarkIds) => {
    const remarks = matchingAuditors
      ?.flatMap(auditor => 
        auditor?.remark?.filter(r => remarkIds.includes(r.id))
      )
      .map(r => r?.remark)
      
    
    return remarks || ""; // Return empty string if no remarks found
  };

  return (
    <div className="accordion-item my-3" key={index}>
      <h2 className="accordion-header" id={`heading${index}`}>
        <button
          className="accordion-button d-flex justify-content-between align-items-center"
          type="button"
          style={{
            backgroundColor: "#BFD7E0",
            color: "black",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          onClick={() => {
            handleAccordionClick(index);
            handleTitle(item);
            handleApplicableCheck(item);
          }}
          // onClick={() => handleAccordionClick(index);handleTitle()}
          aria-expanded={activeIndex === index}
          aria-controls={`collapse${index}`}
        >
          <div style={{ flex: "0 0 70%" }}>
            <span style={{ color: "black", marginBottom: "10px" }}>
              {index + 1}. {item.title.replace(/\b(Yes|No)\b/g, "")}
            </span>
            {assignedToDetails?.assignedToDetails && (
              <div style={{ color: "grey", fontSize: "12px" }}>
                {"Assigned To :- "}
                {assignedToDetails.assignedToDetails
                  .map((detail) => detail?.first_name)
                  .filter((name) => name) // Filter out any undefined or null names
                  .join(", ")}
              </div>
            )}
          </div>

          <StatusWithTooltip status={status}/>

          <div
            style={{
              flex: "0 0 15%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <span
              className="btn btn-sm btn-outline-secondary ms-2"
              style={{
                fontWeight: "bold",
                border: "1.5px solid",
                borderColor: "black",
                padding: "0.4%",
                paddingLeft: "5%",
                paddingRight: "5%",
              }}
            >
              {activeIndex === index ? "-" : "+"}
            </span>
          </div>
        </button>
      </h2>
      <div
        id={`collapse${index}`}
        className={`accordion-collapse collapse ${
          activeIndex === index ? "show" : ""
        }`}
        aria-labelledby={`heading${index}`}
        data-bs-parent="#accordionExample"
      >
        <div className="accordion-body">
          <div
            className=""
            style={{
              background: "#E3EBED",
              height: "40px",
              width: "130%",
              marginTop: "-4%",
              marginLeft: "-4.5%",
            }}
          ></div>
          <div className="p-3 ">
            <div
              style={{
                border: "1px solid black",
                padding: "20px",
                borderRadius: "10px",
                marginTop: "2%",
                display: "flex",
                justifyContent: "space-between", // This will space out the child divs
                alignItems: "center", // This will vertically align the child divs in the middle
              }}
            >
              <FrequencyComponent
                sourceData={sourceData}
                answer={answer}
                setAnswer={setAnswer}
                startingMonth={startingMonth}
                item={menu === "audit" ? item.question : item}
                handlePeriodSelect={handlePeriodSelect}
                selectedPeriod={selectedPeriod}
                financialYear={financialYear}
                setFromDate={setFromDate}
                setToDate={setToDate}
                assignedToDetails={assignedToDetails}
                currentUserId={currentUserId}
                menu={menu}
                setSourceChange={setSourceChange}
              />
            </div>
            <div
              style={{
                border: "1px solid black",
                padding: "20px",
                borderRadius: "10px",
                marginTop: "2%",
              }}
            >
              <Form>
                {/* First Row with 5 input texts */}
                <Row className="mb-3">
                  <Col className="col-20">
                    <Form.Group controlId="formInput1">
                      <Form.Label className="custom-label">
                        Assigned By
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        style={{ backgroundColor: "#BFD7E0" }}
                        value={
                          assignedToDetails?.assignedByDetails?.[0]
                            ?.first_name || ""
                        }
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-20">
                    <Form.Group controlId="formInput2">
                      <Form.Label className="custom-label">
                        Assigned To
                      </Form.Label>
                      <Form.Control
                        as="select"
                        className="form-control"
                        style={{ backgroundColor: "#BFD7E0" }}
                        value={selectedValue}
                        onChange={handleChange}
                        readOnly
                      >
                        {assignedToDetails?.assignedToDetails?.map(
                          (detail, index) => (
                            <option key={index} value={detail?.first_name}>
                              {detail?.first_name}
                            </option>
                          )
                        )}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col className="col-20">
                    <Form.Group controlId="formInput3">
                      <Form.Label className="custom-label">
                        Assign Date
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        style={{ backgroundColor: "#BFD7E0" }}
                        value={
                          assignedToDetails?.createdAt
                            ? new Date(
                                assignedToDetails.createdAt
                              ).toLocaleDateString("en-GB")
                            : ""
                        }
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-20">
                    <Form.Group controlId="formInput4">
                      <Form.Label className="custom-label">Due Date</Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        style={{ backgroundColor: "#BFD7E0" }}
                        value={
                          assignedToDetails?.dueDate
                            ? new Date(
                                assignedToDetails.dueDate
                              ).toLocaleDateString("en-GB")
                            : ""
                        }
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col className="col-20">
                    <Form.Group controlId="formInput5">
                      <Form.Label className="custom-label">
                        Answered Date
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        style={{ backgroundColor: "#BFD7E0" }}
                        value={
                          assignedToDetails?.updatedAt
                            ? new Date(
                                assignedToDetails.updatedAt
                              ).toLocaleDateString("en-GB")
                            : ""
                        }
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {/* Second Row with 4 input texts */}
                <Row className="mb-3">
                  <Col>
                    <Form.Group controlId="formInput6">
                      <Form.Label className="custom-label">
                        Audited By
                      </Form.Label>
                      <Form.Control
                        type="text"
                        style={{ backgroundColor: "#BFD7E0" }}
                        value={
                          mappedUserData
                            ?.map((user) => user.first_name)
                            .join(", ") || ""
                        }
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formInput7">
                      <Form.Label className="custom-label">
                        Audited Date
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        style={{ backgroundColor: "#BFD7E0" }}
                        value={startDate ? startDate.toLocaleDateString() : ""}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formInput8">
                      <Form.Label className="custom-label">
                        Question Status
                      </Form.Label>
                      <Form.Control
                        type="text"
                        style={{ backgroundColor: "#BFD7E0" }}
                        value={assignedToDetails?.questionStatus || ""}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formInput9">
                      <Form.Label className="custom-label">
                        Auditor Remark
                      </Form.Label>
                      <Form.Control
                        type="text"
                        style={{ backgroundColor: "#BFD7E0" }}
                        value={getRemarks(item.matchingAuditors, item?.matchingAuditors.flatMap(
                          (auditor) => auditor?.remark?.map((r) => r.id) || []
                        )) || ""}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </div>
            {(menu === "audit"
              ? item?.question?.applicableCheck
              : item?.applicableCheck) === 1 && (
              <div className="checkkbox">
                <div
                  className="checkkbox d-flex mt-4"
                  style={{ marginLeft: "5px" }}
                >
                  <div className="form-check" style={{ fontSize: "1rem" }}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="applicableCheckbox"
                      checked={applicable}
                      onChange={() => handleApplicableChange(true)} // Set applicable to true
                      style={{ transform: "scale(1.5)" }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="applicableCheckbox"
                    >
                      Applicable
                    </label>
                  </div>
                  <div
                    className="form-check"
                    style={{ marginLeft: "1rem", fontSize: "1rem" }}
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="notApplicableCheckbox"
                      checked={!applicable}
                      onChange={() => handleApplicableChange(false)} // Set applicable to false
                      style={{ transform: "scale(1.5)" }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="notApplicableCheckbox"
                    >
                      Not Applicable
                    </label>
                  </div>
                </div>
                {!applicable ? (
                  <div>
                    <Row>
                      <Col md={12}>
                        <Form.Group controlId="formInput12">
                          <Form.Label className="custom-label">Note</Form.Label>
                          <Form.Control
                            style={{ backgroundColor: "#BFD7E0" }}
                            type="text"
                            value={note[0][0]}
                            onChange={handleNoteChange}
                            readOnly={
                              menu === "audit" ||
                              (assignedToDetails?.assignedTo?.length > 0 &&
                                !assignedToDetails?.assignedTo?.some(
                                  (id) => parseInt(id, 10) === currentUserId
                                ))
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Col md={8}>
                      <Form.Group controlId="formInput12">
                        <Form.Label className="custom-label">Remark</Form.Label>
                        <Form.Control
                          style={{ backgroundColor: "#BFD7E0" }}
                          type="text"
                          value={remark}
                          onChange={handleRemarkChange}
                        />
                      </Form.Group>
                    </Col>

                    <div>
                      {menu === "audit" ? (
                        <>
                          <div style={{ display: "flex", marginTop: "20px" }}>
                            <button
                              className="btn"
                              onClick={handleAccept}
                              disabled={auditButton}
                              style={{
                                borderColor: "white",
                                backgroundColor: "#3F88A5",
                                padding: "2%",
                                paddingLeft: "10%",
                                paddingRight: "10%",
                              }}
                            >
                              Accept
                            </button>
                            <button
                              className="btn"
                              onClick={handleReject}
                              disabled={auditButton}
                              style={{
                                borderColor: "white",
                                backgroundColor: "#3F88A5",
                                padding: "1%",
                                paddingLeft: "10%",
                                paddingRight: "10%",
                              }}
                            >
                              Reject
                            </button>
                          </div>
                        </>
                      ) : (
                        <button
                          className="btn btn-primary"
                          onClick={handleSubmit}
                          disabled={
                            item?.frequency === "CUSTOM" && !selectedPeriod
                          }
                          style={{
                            borderColor: "white",
                            backgroundColor: "#3F88A5",
                            paddingLeft: "5%",
                            paddingRight: "5%",
                            marginTop: "5%",
                          }}
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )}

            {applicable && (
              <div
                style={{
                  border: "1px solid black",
                  padding: "20px",
                  borderRadius: "10px",
                  marginTop: "2%",
                }}
              >
                {item?.questionType === "qualitative" && (
                  <QualitativeComponent
                    sourceData={sourceData}
                    selectedPeriod={selectedPeriod}
                    getAuditListing={getAuditListing}
                    questionType={item.questionType}
                    currentUserId={currentUserId}
                    dueDate={assignedToDetails?.dueDate}
                    assignedToDetails={assignedToDetails}
                    menu={menu}
                    savedAnswers={savedAnswers}
                    item={menu === "audit" ? item.question : item}
                    {...(menu === "audit" ? { answerId: item?.answer } : {})}
                    answer={answer}
                    auditorId={auditorId}
                    setAnswer={setAnswer}
                    financeObject={financeObject}
                    title={item.title}
                    toDate={toDate}
                    fromDate={fromDate}
                  />
                )}
                {item?.questionType === "quantitative" && (
                  <QualitativeComponent
                    sourceData={sourceData}
                    questionType={item.questionType}
                    assignedToDetails={assignedToDetails}
                    currentUserId={currentUserId}
                    dueDate={assignedToDetails?.dueDate}
                    selectedPeriod={selectedPeriod}
                    menu={menu}
                    getAuditListing={getAuditListing}
                    auditorId={auditorId}
                    answer={answer}
                    {...(menu === "audit" ? { answerId: item?.answer } : {})}
                    savedAnswers={savedAnswers}
                    setAnswer={setAnswer}
                    item={menu === "audit" ? item.question : item}
                    financeObject={financeObject}
                    title={item.title}
                    toDate={toDate}
                    fromDate={fromDate}
                  />
                )}
                {item?.questionType === "tabular_question" && (
                  <TabularComponent
                    sourceData={sourceData}
                    getAuditListing={getAuditListing}
                    assignedToDetails={assignedToDetails}
                    currentUserId={currentUserId}
                    menu={menu}
                    auditorId={auditorId}
                    dueDate={assignedToDetails?.dueDate}
                    answer={answer}
                    selectedPeriod={selectedPeriod}
                    savedAnswers={savedAnswers}
                    auditItem={item}
                    title={item.title}
                    {...(menu === "audit" ? { answerId: item?.answer } : {})}
                    setAnswer={setAnswer}
                    item={menu === "audit" ? item.question : singleItem}
                    financeObject={financeObject}
                    toDate={toDate}
                    fromDate={fromDate}
                    sourceChange={sourceChange}
                  />
                )}
                {item?.questionType === "yes_no" && (
                  <YesNoComponent
                    sourceData={sourceData}
                    assignedToDetails={assignedToDetails}
                    currentUserId={currentUserId}
                    {...(menu === "audit" ? { answerId: item?.answer } : {})}
                    answer={answer}
                    getAuditListing={getAuditListing}
                    auditorId={auditorId}
                    menu={menu}
                    dueDate={assignedToDetails?.dueDate}
                    selectedPeriod={selectedPeriod}
                    savedAnswers={savedAnswers}
                    setAnswer={setAnswer}
                    item={menu === "audit" ? item.question : singleItem}
                    financeObject={financeObject}
                    title={item.title}
                    toDate={toDate}
                    fromDate={fromDate}
                  />
                )}
                {item?.questionType === "quantitative_trends" && (
                  <TrendsComponent
                    sourceData={sourceData}
                    assignedToDetails={assignedToDetails}
                    answer={answer}
                    selectedPeriod={selectedPeriod}
                    currentUserId={currentUserId}
                    title={item.title}
                    auditorId={auditorId}
                    getAuditListing={getAuditListing}
                    menu={menu}
                    dueDate={assignedToDetails?.dueDate}
                    financeObject={financeObject}
                    savedAnswers={savedAnswers}
                    setAnswer={setAnswer}
                    item={menu === "audit" ? item.question : singleItem}
                    toDate={toDate}
                    fromDate={fromDate}
                    sourceChange={sourceChange}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainAccordComponent;
