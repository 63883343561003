import React, { useState, useEffect } from "react";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import Chart from "react-apexcharts";
import "./workloadprogress.css";

const WorkloadProgress = ({fromDate,toDate,financialYearId}) => {
  const [teamWorkloadData, setTeamWorkloadData] = useState([]);
  const [names, setNames] = useState([]);
  const [showChart, setShowChart] = useState(false);

  const [percentageAccepted, setPercentageAccepted] = useState([]);
  const [percentageRejected, setPercentageRejected] = useState([]);
  const [percentageAnswered, setPercentageAnswered] = useState([]);
  const [percentageUnresponded, setPercentageUnresponded] = useState([]);
  const [selectedOption, setSelectedOption] = useState("percentageAnswered");
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({
    colors: ["#11546F"], // Set the color of the bars
    chart: {
      type: "bar",
      height: "170%",
      toolbar: {
        show: false, // Disable the toolbar
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [],
      labels: {
        style: {
          colors: ["#11546F"], // Set the color of the labels on the x-axis
        },
        rotate: 0, // Make labels horizontal
      },
    },
    yaxis: {
      min: 0, // Set the minimum value of the y-axis to 0
      max: 100, // Set the maximum value of the y-axis to 100
      labels: {
        formatter: function (val) {
          return val.toFixed(2); // Format the label as a percentage
        },
      },
      tickAmount: 5, // Adjust the number of ticks
      forceNiceScale: true, // Ensure ticks are generated nicely
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return `${val}%`;
        },
      },
    },
  });
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 4;

  const teamWorkloadProgess = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}teamWorkloadProgess`,
      {},
      {fromDate:fromDate,
        toDate:toDate,
        financialYearId: financialYearId },
      "GET"
    );
    if (isSuccess) {
      const results = data?.data?.teamWorkloadResults;
      const dataMap = {
        percentageAccepted: results.map((d) => parseFloat(d.percentageAccepted)),
        percentageRejected: results.map((d) => parseFloat(d.percentageRejected)),
        percentageAnswered: results.map((d) => parseFloat(d.percentageAnswered)),
        percentageUnresponded: results.map((d) => parseFloat(d.percentageUnresponded)),
      };
      const startIndex = currentPage * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const currentNames = results.map((d) => `${d.firstName}`).slice(startIndex, endIndex);
      const currentData = dataMap[selectedOption].slice(startIndex, endIndex);
      setSeries([{ name: selectedOption.replace("percentage", ""), data: currentData }]);
      setOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: { ...prevOptions.xaxis, categories: currentNames },
      }));
      setTeamWorkloadData(results);
      setNames(results.map((d) => `${d.firstName}`));
      setPercentageAccepted(results.map((d) => parseFloat(d.percentageAccepted)));
      setPercentageRejected(results.map((d) => parseFloat(d.percentageRejected)));
      setPercentageAnswered(results.map((d) => parseFloat(d.percentageAnswered)));
      setPercentageUnresponded(results.map((d) => parseFloat(d.percentageUnresponded)));
    }
  };

  useEffect(() => {
    const dataMap = {
      percentageAccepted: percentageAccepted,
      percentageRejected: percentageRejected,
      percentageAnswered: percentageAnswered,
      percentageUnresponded: percentageUnresponded,
    };

    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentNames = names.slice(startIndex, endIndex);
    const currentData = dataMap[selectedOption].slice(startIndex, endIndex);

    setSeries([{ name: selectedOption.replace("percentage", ""), data: currentData }]);
    setOptions((prevOptions) => ({
      ...prevOptions,
      xaxis: { ...prevOptions.xaxis, categories: currentNames },
    }));
  }, [selectedOption, currentPage]);

  useEffect(() => {
    teamWorkloadProgess();
    const timer = setTimeout(() => setShowChart(true), 1000);
    return () => clearTimeout(timer); // Cleanup on unmount
  }, [fromDate,toDate,financialYearId]);

  const handleSelectChange = (e) => {
    const value = e.target.value;
    switch (value) {
      case "accepted":
        setSelectedOption("percentageAccepted");
        break;
      case "rejected":
        setSelectedOption("percentageRejected");
        break;
      case "answered":
        setSelectedOption("percentageAnswered");
        break;
      case "not-responded":
        setSelectedOption("percentageUnresponded");
        break;
      default:
        break;
    }
  };

  const handlePrevPage = () => setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));

  const handleNextPage = () =>
    setCurrentPage((prevPage) =>
      Math.min(prevPage + 1, Math.ceil(names.length / itemsPerPage) - 1)
    );

  return (
    <div className="workloadcont">
      <div style={{ marginBottom: "10px" }}>
        <h5>Assigned Questions and Progress by Assignee</h5>
      </div>
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="select-wrapper">
            <select
              className="form-select esg_text2"
              onChange={handleSelectChange}
              value={
                selectedOption === "percentageAccepted"
                  ? "accepted"
                  : selectedOption === "percentageRejected"
                  ? "rejected"
                  : selectedOption === "percentageAnswered"
                  ? "answered"
                  : selectedOption === "percentageUnresponded"
                  ? "not-responded"
                  : ""
              }
            >
              <option value="accepted">Accepted</option>
              <option value="rejected">Rejected</option>
              <option value="answered">Answered</option>
              <option value="not-responded">Not Responded</option>
            </select>
          </div>
          <div>
            <span className="m-n20">Tasks by Assignee</span>
          </div>
        </div>
      </div>
      <div className="graph-wrapper">
        <div className="graph-cont" style={{ display: selectedOption ? "block" : "none" }}>
          <div style={{ overflowX: "scroll" }}>
            {selectedOption && showChart && <Chart options={options} series={series} type="bar" height="170%" />}
          </div>
          <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
            <div className="pagination-arrows" onClick={handlePrevPage} style={{ marginRight: "10px", cursor: "pointer" }}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32" fill="#11546F">
                <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
                <path d="M0 0h24v24H0z" fill="none" />
              </svg>
            </div>
            <div className="pagination-arrows" onClick={handleNextPage} style={{ cursor: "pointer" }}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32" fill="#11546F">
                <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
                <path d="M0 0h24v24H0z" fill="none" />
              </svg>
            </div>
          </div>
        </div>
        <div style={{ display: !selectedOption ? "block" : "none" }}>
          <p>Please select an option to view the graph.</p>
        </div>
      </div>
    </div>
  );
};

export default WorkloadProgress;

