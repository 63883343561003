import React from "react";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import due from "../../img/Due.svg";
import updated from "../../img/updated.svg";
import done from "../../img/shape.svg";
import defaulted from "../../img/Defaulted.svg";
import { useState } from "react";
import { useEffect } from "react";
import TopComponentEmission from "./TopComponentEmission";
import LocationWiseEmission from "./LocationWiseEmission";
import GHGEmissions from "./GHGEmissions";
import EmissionAcrossLocation from "./EmissionAcrossLocation";
import ProductWiseEmission from "./ProductWiseEmission";
import ScopeTwo from "./ScopeTwo";
import ComparisonScope from "./ComparisonScope";
import ComparisonAcrossYear from "./ComparisonAcrossYear";
import TotalEmissionGenerated from "./TotalCommissionGranted";
import TotalGHGEmissionPercentage from "./TotalGHGEmission";
import ScopeMultiLocComparison from "./ScopeComMulLoc";
import TotalCarbonEmission from "./TotalCarbonEmission";
import EmissionIntensityOverTime from "./EmissionIIntensityOverTime";
import CarbonEmissionByScope from "./CarbonEmissionByScope";
import LineChartWithOptions from "./LineChartEmission";
import MixedChart from "./MixedChart";
import Speedometer from "./Speedometer";

const Emission = ({
  locationOption,
  timePeriods,
  financialYearId,
  graphData,
}) => {
  const location = 2;
  const [lastWeekAcitivities, setLastWeekAcitivities] = useState();
  const icons = {
    done: done,
    updated: updated,
    due: due,
    pending: defaulted,
  };

  const lastWeekActivity = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}lastWeekActivity`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      const data = {
        "Total Emission": {
          "number": '2009',
          "questionId": [ ]
        },
        "Scope 1 Emission": {
          "number": '332',
       "questionId": []
        },
        "Scope 2 Emission": {
          "number": '1677',
          "questionId": []
        },
  
        "message": "Good Evening, Sunil Kumar"
      }
      setLastWeekAcitivities(data);
    }
  };

  useEffect(() => {
    lastWeekActivity();
  }, []);
  return (
    <div className="progress-container">
      <div className="topcompo">
        {lastWeekAcitivities && (
          <TopComponentEmission
            lastWeekAcitivities={lastWeekAcitivities}
            icons={icons}
          />
        )}
      </div>
      {location === 1 && (
        <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy">
              <LocationWiseEmission />
            </div>
            <div className="secondhalfprogress">
              <GHGEmissions />
            </div>
          </div>
          <div
            className="d-flex"
            style={{ height: "40vh", marginBottom: "3%" }}
          >
            <EmissionAcrossLocation />
          </div>
          <div
            className="d-flex"
            style={{ height: "60vh", marginBottom: "3%" }}
          >
            <ProductWiseEmission />
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="secondhalfprogress">
              <ScopeTwo />
            </div>
            <div
              className="firsthalfprogressenergy"
              style={{ marginLeft: "2%" }}
            >
              <ComparisonScope />
            </div>
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh" }}
          >
            <ComparisonAcrossYear />
          </div>
        </div>
      )}
      {location >= 1 && (
        <div className="d-flex flex-column flex-space-between">
          {/* <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy">
              <TotalEmissionGenerated />
            </div>
            <div className="secondhalfprogress">
              <TotalGHGEmissionPercentage />
            </div>
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy">
              <ScopeMultiLocComparison />
            </div>
            <div className="secondhalfprogress">
              <TotalCarbonEmission />
            </div>
          </div> */}
          {/* <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy">
              <EmissionIntensityOverTime />
            </div>
            <div className="secondhalfprogress">
              <CarbonEmissionByScope />
            </div>
          </div> */}
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy">
              <LineChartWithOptions locationOption = {locationOption} timePeriods = {timePeriods} financialYearId = {financialYearId} graphData = {graphData}/>
            </div>
            <div className="secondhalfprogress">
              <ScopeMultiLocComparison locationOption = {locationOption} timePeriods = {timePeriods} financialYearId = {financialYearId} graphData = {graphData}/>
            </div>
          </div>
          {/* <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy">
              <LineChartWithOptions locationOption = {locationOption} timePeriods = {timePeriods} financialYearId = {financialYearId} graphData = {graphData} />
            </div>
            <div className="secondhalfprogress">
              <ScopeMultiLocComparison   locationOption = {locationOption} timePeriods = {timePeriods} financialYearId = {financialYearId} graphData = {graphData}/>
            </div>
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy">
              <MixedChart />
            </div>
            <div className="secondhalfprogress">
              <Speedometer />
            </div>
          </div> */}
        </div>
      )}
    </div>
  );
};

export default Emission;
