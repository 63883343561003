import React, { useState, useEffect } from "react";
import { Form, Dropdown, Button, Row, Col } from "react-bootstrap";
import { apiCall } from "../../../_services/apiCall";
import config from "../../../config/config.json";

const FrequencyComponent = ({
  sourceData,
  answer,
  setAnswer,
  startingMonth,
  item,
  handlePeriodSelect,
  selectedPeriod,
  financialYear,
  setFromDate,
  setToDate,
  assignedToDetails,
  currentUserId,
  menu,
  setSourceChange,
}) => {
  // const [fromDate, setFromDate] = useState(null);
  // const [toDate, setToDate] = useState(null);
  const [meterList, setMeterList] = useState([]);
  const [selectedSource, setSelectedSource] = useState("");
  const [sourceName, setSourceName] = useState("");
  const formatDate = (year, month) => {
    const formattedMonth = month.toString().padStart(2, "0"); // Ensure two digits for the month
    return `${year}-${formattedMonth}`;
  };

  useEffect(() => {
    if(sourceData){
      const locationArray = sourceData?.reverse().map((item) => ({
        id: item.id,
        location: `${item?.location?.area}, ${item?.location?.city}, ${item?.location?.state}, ${item?.location?.country}, ${item?.location?.zipCode}`,
        unitCode: item?.unitCode,
      }));
      if(locationArray && locationArray.length){
        setMeterList(locationArray);
        setSelectedSource(locationArray[0]?.unitCode || locationArray[0]?.location);
        setSourceChange(locationArray[0]?.id);
        setAnswer((prevAnswer) => ({
          ...prevAnswer,
          sourceId: locationArray[0]?.id,
        }));
  
      }
    }
    
   
  }, [sourceData]);

  const handleSourceSelect = (key) => {
    const selectedItem = meterList.find((item) => item.id.toString() === key);
    if (selectedItem) {
      // Set the selected source to the item's location (name)
      setSelectedSource(selectedItem?.unitCode || selectedItem.location);
      setSourceChange(selectedItem?.id);
      // Update the answer state with the selected item's id
      setAnswer((prevAnswer) => ({
        ...prevAnswer,
        sourceId: Number(key),
      }));
    }
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "20px",
      width: "100%",
    },
    text: {
      color: "rgba(0, 0, 0, 0.70)",
      fontSize: "12px",
      fontFamily: "Open Sans",
      fontWeight: 400,
    },
    uploadDiv: {
      width: "100%",
      backgroundColor: "#3F88A5",
      borderRadius: "10px",
      height: "30px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      color: "white",
      position: "relative",
      border: "2px dashed #3F88A5",
      transition: "background-color 0.3s ease",
    },
    uploadDivHover: {
      backgroundColor: "#30707E", // Darker blue on hover
    },
    icon: {
      position: "absolute",
      right: "10px",
      fontSize: "10px",
      color: "white",
    },
    fileInput: {
      display: "none",
    },
    formGroup: {
      marginBottom: "15px",
    },
  };
  const getDropdownItems = () => {
    if (!item?.answerFrequency) return null;

    switch (item.answerFrequency) {
      case "MONTHLY":
        return Array.from({ length: 12 }, (_, i) => (
          <Dropdown.Item key={`M${i + 1}`} eventKey={`M${i + 1}`}>
            M{i + 1}
          </Dropdown.Item>
        ));
      case "QUARTERLY":
        return ["Q1", "Q2", "Q3", "Q4"].map((quarter) => (
          <Dropdown.Item key={quarter} eventKey={quarter}>
            {quarter}
          </Dropdown.Item>
        ));
      case "HALF_YEARLY":
        return ["H1", "H2"].map((halfYear) => (
          <Dropdown.Item key={halfYear} eventKey={halfYear}>
            {halfYear}
          </Dropdown.Item>
        ));
      case "YEARLY":
        return (
          <Dropdown.Item key="Y1" eventKey="Y1">
            Y1
          </Dropdown.Item>
        );
      default:
        return null;
    }
  };

  const calculateDates = (period) => {
    const [startYear, endYear] = financialYear.split("-").map(Number);
    let baseYear = startYear;
    let baseMonth = startingMonth;

    let fromDate, toDate; // Declare variables to store fromDate and toDate

    const calculateMonthYear = (baseYear, baseMonth, monthOffset) => {
      const adjustedMonth = ((baseMonth - 1 + monthOffset) % 12) + 1;
      const yearOffset = Math.floor((baseMonth - 1 + monthOffset) / 12);
      const adjustedYear = baseYear + yearOffset;
      return { year: adjustedYear, month: adjustedMonth };
    };

    switch (period) {
      // Monthly cases
      case "M1":
        const m1 = calculateMonthYear(baseYear, baseMonth, 0);
        fromDate = formatDate(m1.year, m1.month);
        toDate = formatDate(m1.year, m1.month + 1);
        break;

      case "M2":
        const m2 = calculateMonthYear(baseYear, baseMonth, 1);
        fromDate = formatDate(m2.year, m2.month);
        toDate = formatDate(m2.year, m2.month + 1);
        break;

      case "M3":
        const m3 = calculateMonthYear(baseYear, baseMonth, 2);
        fromDate = formatDate(m3.year, m3.month);
        toDate = formatDate(m3.year, m3.month + 1);
        break;

      case "M4":
        const m4 = calculateMonthYear(baseYear, baseMonth, 3);
        fromDate = formatDate(m4.year, m4.month);
        toDate = formatDate(m4.year, m4.month + 1);
        break;

      case "M5":
        const m5 = calculateMonthYear(baseYear, baseMonth, 4);
        fromDate = formatDate(m5.year, m5.month);
        toDate = formatDate(m5.year, m5.month + 1);
        break;

      case "M6":
        const m6 = calculateMonthYear(baseYear, baseMonth, 5);
        fromDate = formatDate(m6.year, m6.month);
        toDate = formatDate(m6.year, m6.month + 1);
        break;

      case "M7":
        const m7 = calculateMonthYear(baseYear, baseMonth, 6);
        fromDate = formatDate(m7.year, m7.month);
        toDate = formatDate(m7.year, m7.month + 1);
        break;

      case "M8":
        const m8 = calculateMonthYear(baseYear, baseMonth, 7);
        fromDate = formatDate(m8.year, m8.month);
        toDate = formatDate(m8.year, m8.month + 1);
        break;

      case "M9":
        const m9 = calculateMonthYear(baseYear, baseMonth, 8);
        fromDate = formatDate(m9.year, m9.month);
        toDate = formatDate(m9.year, m9.month + 1);
        break;

      case "M10":
        const m10 = calculateMonthYear(baseYear, baseMonth, 9);
        fromDate = formatDate(m10.year, m10.month);
        toDate = formatDate(m10.year, m10.month + 1);
        break;

      case "M11":
        const m11 = calculateMonthYear(baseYear, baseMonth, 10);
        fromDate = formatDate(m11.year, m11.month);
        toDate = formatDate(m11.year, m11.month + 1);
        break;

      case "M12":
        const m12 = calculateMonthYear(baseYear, baseMonth, 11);
        fromDate = formatDate(m12.year, m12.month);
        toDate = formatDate(m12.year, m12.month + 1);
        break;

      // Quarterly cases
      case "Q1":
        fromDate = formatDate(baseYear, baseMonth);
        toDate = formatDate(
          baseMonth + 3 > 12 ? baseYear + 1 : baseYear,
          baseMonth + 3 > 12 ? baseMonth + 3 - 12 : baseMonth + 3
        );
        break;

      case "Q2":
        fromDate = formatDate(
          baseMonth + 3 > 12 ? baseYear + 1 : baseYear,
          baseMonth + 3 > 12 ? baseMonth + 3 - 12 : baseMonth + 3
        );
        toDate = formatDate(
          baseMonth + 6 > 12 ? baseYear + 1 : baseYear,
          baseMonth + 6 > 12 ? baseMonth + 6 - 12 : baseMonth + 6
        );
        break;

      case "Q3":
        fromDate = formatDate(
          baseMonth + 6 > 12 ? baseYear + 1 : baseYear,
          baseMonth + 6 > 12 ? baseMonth + 6 - 12 : baseMonth + 6
        );
        toDate = formatDate(
          baseMonth + 9 > 12 ? baseYear + 1 : baseYear,
          baseMonth + 9 > 12 ? baseMonth + 9 - 12 : baseMonth + 9
        );
        break;

      case "Q4":
        fromDate = formatDate(
          baseMonth + 9 > 12 ? baseYear + 1 : baseYear,
          baseMonth + 9 > 12 ? baseMonth + 9 - 12 : baseMonth + 9
        );
        toDate = formatDate(
          baseYear + 1,
          baseMonth === 1 ? 1 : ((baseMonth - 1 + 12) % 12) + 1
        );
        break;

      // Half-yearly cases
      case "H1":
        fromDate = formatDate(baseYear, baseMonth);
        toDate = formatDate(
          baseMonth + 6 > 12 ? baseYear + 1 : baseYear,
          baseMonth + 6 > 12 ? baseMonth + 6 - 12 : baseMonth + 6
        );
        break;

      case "H2":
        fromDate = formatDate(
          baseMonth + 6 > 12 ? baseYear + 1 : baseYear,
          baseMonth + 6 > 12 ? baseMonth + 6 - 12 : baseMonth + 6
        );
        toDate = formatDate(
          baseYear + 1,
          baseMonth === 1 ? 1 : ((baseMonth - 1 + 12) % 12) + 1
        );
        break;

      // Yearly case
      case "Y1":
        fromDate = formatDate(baseYear, baseMonth);
        toDate = formatDate(baseYear + 1, baseMonth);
        break;

      default:
        fromDate = "";
        toDate = "";
        break;
    }

    // Set the answer state after the switch statement
    setFromDate(fromDate);
    setToDate(toDate);
    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      fromDate: fromDate,
      toDate: toDate,
    }));
  };

 
  useEffect(() => {
    if (selectedPeriod) {
      calculateDates(selectedPeriod);
    }
  }, [selectedPeriod]);

  return (
    <>
      <div style={{ width: "30%" }}>
        <div className="d-flex">
          <div>
            {meterList?.length === 1 ? (
              <></>
            ) : (
              <Row>
                <Col md={6}>
                  <Form.Group controlId="formInput10" style={styles.formGroup}>
                    <Form.Label className="custom-label">Source</Form.Label>
                    <div className="select-wrapper w-100">
                      <Dropdown onSelect={handleSourceSelect}>
                        {meterList?.length === 1 ? (
                          <div
                            style={{
                              backgroundColor: "#BFD7E0",
                              color: "black",
                              borderColor: "white",
                              // width: "100%",
                              overflow: "auto",
                              padding: "10px",
                              borderRadius: "4px",
                            }}
                          >
                            {meterList[0]?.unitCode || meterList[0]?.location}
                          </div>
                        ) : (
                          <>
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              // readOnly={
                              //   menu === "audit" ||
                              //   (assignedToDetails?.assignedTo?.length > 0 &&
                              //     parseInt(assignedToDetails.assignedTo[0], 10) !==
                              //       currentUserId)
                              // }
                              readOnly={
                                menu === "audit" ||
                                (assignedToDetails?.assignedTo?.length > 0 &&
                                  !assignedToDetails?.assignedTo?.some(
                                    (id) => parseInt(id, 10) === currentUserId
                                  ))
                              }
                              style={{
                                backgroundColor: "#BFD7E0",
                                color: "black",
                                borderColor: "white",
                                // width: "100%",
                                overflow: "auto",
                              }}
                            >
                              <span style={{ marginRight: "5%" }}>
                                {sourceName ||
                                  selectedSource ||
                                  "Select Source"}
                              </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {meterList?.map((item) => (
                                <Dropdown.Item key={item.id} eventKey={item.id}>
                                  {item?.unitCode || item?.location}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </>
                        )}
                      </Dropdown>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            )}
          </div>
          <div className="mx-5">
            <Form.Group controlId="formInput10">
              <Form.Label className="custom-label">
                Reporting Period Selection
              </Form.Label>
              <div className="select-wrapper">
                {item?.frequency === "ONE_TIME" ||
                item?.frequency === "EVERY_FY" ? (
                  <Form.Control
                    type="text"
                    value={
                      item?.frequency === "ONE_TIME"
                        ? "One Time"
                        : item?.frequency === "EVERY_FY"
                        ? "Every Financial Year"
                        : item?.frequency
                    }
                    readOnly
                    style={{
                      backgroundColor: "#BFD7E0",
                      color: "black",
                    }}
                  />
                ) : item?.frequency === "CUSTOM" ? (
                  <Dropdown onSelect={handlePeriodSelect}>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      style={{
                        backgroundColor: "#BFD7E0",
                        color: "black",
                        borderColor: "white",
                      }}
                    >
                      <span style={{ marginRight: "5%" }}>
                        {selectedPeriod || "Select Period"}
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>{getDropdownItems()}</Dropdown.Menu>
                  </Dropdown>
                ) : null}
              </div>
            </Form.Group>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "40%",
          textAlign: "right",
          marginTop: "25px",
        }}
      >
        <Button
          variant=""
          style={{
            padding: "8px 30px",
            marginRight: 0,
            backgroundColor: "transparent",
            borderRadius: "5px",
            borderColor: "#3F88A5",
            fontSize: "14px",
            fontFamily: "Open Sans",
            fontWeight: "700",
          }}
          onClick={() => console.log("History clicked")}
        >
          History
        </Button>
      </div>
    </>
  );
};

export default FrequencyComponent;
