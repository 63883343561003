import React from "react";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import due from "../../img/Due.svg";
import updated from "../../img/updated.svg";
import done from "../../img/shape.svg";
import defaulted from "../../img/Defaulted.svg";
import { useState } from "react";
import { useEffect } from "react";
import TopComponentSafety from "./TopComponentSafety";
import WellBeingMeasures from "./WellBeingMeasures";
import SafetyRelatedIncidents from "./SafetyRelatedIncidents";
import IncidentRateAnalysis from "./IncidentRateAnalysis";
import SeverityRate from "./SevrityRate";
import SafetyTrainingCompletionRate from "../Training/SafetyTrainingCompletionRate";
import MeasuresForTheWellBeing from "./MeasuresForTheWellBeing";
import OccupationalHealth from "./OccupationalHealth";
import SafetyTrainingCompletionRateWorkers from "./SafetyTrainingCompletionRatesWorker";
import SafetyStandards from "./SafetyStandards";

const Safety = () => {
  const location = 2;
  const [lastWeekAcitivities, setLastWeekAcitivities] = useState();
  const icons = {
    done: done,
    updated: updated,
    due: due,
    pending: defaulted,
  };

  const lastWeekActivity = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}lastWeekActivity`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      setLastWeekAcitivities(data?.data);
    }
  };

  useEffect(() => {
    lastWeekActivity();
  }, []);
  return (
    <div className="progress-container">
      <div className="topcompo">
        {lastWeekAcitivities && (
          <TopComponentSafety
            lastWeekAcitivities={lastWeekAcitivities}
            icons={icons}
          />
        )}
      </div>

      {location === 1 && (
        <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <WellBeingMeasures />
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <SafetyRelatedIncidents />
          </div>
        </div>
      )}
      {location >= 2 && (
        <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy" style={{ width: "50%" }}>
              <IncidentRateAnalysis />
            </div>
            <div className="secondhalfprogress" style={{ width: "50%" }}>
              <SeverityRate />
            </div>
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <SafetyTrainingCompletionRate/>
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
             <div className="firsthalfprogressenergy" style={{ width: "50%" }}>
              <MeasuresForTheWellBeing/>
            </div>
            <div className="secondhalfprogress" style={{ width: "50%" }}>
              <OccupationalHealth/>
            </div>

          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <SafetyTrainingCompletionRateWorkers/>
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <SafetyStandards/>
          </div>

        </div>
      )}
    </div>
  );
};

export default Safety;
