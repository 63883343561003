import React, { useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Dropdown,
  InputGroup,
  Modal,
  Button,
} from "react-bootstrap";
import { useState } from "react";
import { apiCall } from "../../../../_services/apiCall";
import config from "../../../../config/config.json";
import swal from "sweetalert";
import { FaFileDownload, FaTrash } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { DetailModal } from "./DetailModal";
const QualitativeComponent = ({
  sourceData,
  getAuditListing,
  questionType,
  dueDate,
  selectedPeriod,
  title,
  currentUserId,
  assignedToDetails,
  menu,
  savedAnswers,
  financeObject,
  answer,
  setAnswer,
  auditorId,
  answerId,
  item,
  toDate,
  fromDate,
}) => {
  console.log("auditor id",auditorId,currentUserId)
  const [remark, setRemark] = useState("");
  const [response, setResponse] = useState(answer?.response || "");
  const [note, setNote] = useState(answer?.note || [[""]]);
  const [answerIdReal, setAnswerIdReal] = useState();
  const [status, setStatus] = useState();
  const [responseError, setResponseError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [isEditable, setIsEditable] = useState(true);
  const [localResponse, setLocalResponse] = useState(response);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showData, setShowData] = useState("");
  const [auditButton,setAuditButton] = useState(false)

  const [check, setCheck] = useState(false);

  useEffect(() => {
    setLocalResponse(response);
  }, [response]);

  const handleDoubleClick = (data) => {
    setShowData(data);
    setIsModalOpen(true);
  };
  const [proofDocument, setProofDocument] = useState(
    answer?.proofDocument || [[]]
  );
  const handleRemarkChange = (e) => {
    const newRemark = e.target.value;
    setRemark(newRemark);
  };

  const initializeAnswer = () => {
    if (item && item.questionId && savedAnswers && savedAnswers.length) {
      // const matchedAnswer = savedAnswers.find(
      //   (savedAnswer) => savedAnswer.questionId == item.questionId
      // );
      let matchedAnswer;
      if (item?.frequency == "CUSTOM") {
        matchedAnswer = savedAnswers.find(
          (savedAnswer) =>
            savedAnswer.questionId == item.questionId &&
            savedAnswer.toDate == toDate &&
            savedAnswer.fromDate == fromDate
        );
        setAnswerIdReal(matchedAnswer?.id);
      } else {
        matchedAnswer = savedAnswers.find(
          (savedAnswer) => savedAnswer.questionId == item.questionId
        );
        setAnswerIdReal(matchedAnswer?.id);
      }
      if (matchedAnswer) {
        setAnswerIdReal(matchedAnswer?.id);
        setStatus(matchedAnswer?.status);
        setCheck(true);
        setResponse(matchedAnswer.answer || "");
        setNote((prevState) => {
          // Check if matchedAnswer.note is a string
          if (typeof matchedAnswer.note === "string") {
            return [[matchedAnswer.note]]; // Wrap the string in a 2D array
          }

          // Check if matchedAnswer.note is a 2D array
          if (
            Array.isArray(matchedAnswer.note) &&
            Array.isArray(matchedAnswer.note[0])
          ) {
            return matchedAnswer.note; // Use it as is
          }

          // If matchedAnswer.note is not a string or a 2D array, return an empty 2D array
          return [[""]];
        });
        if (Array.isArray(matchedAnswer.proofDocument)) {
          // Check if the first element is an array (indicating a 2D array)
          if (Array.isArray(matchedAnswer.proofDocument[0])) {
            setProofDocument(matchedAnswer.proofDocument);
          } else {
            // It's a 1D array, so wrap it in another array to make it 2D
            setProofDocument([matchedAnswer.proofDocument]);
          }
        } else {
          // If matchedAnswer.proofDocument is not an array, set it to an empty 2D array
          setProofDocument([[]]);
        }
        setAnswer((prevState) => ({
          ...prevState,
          questionId: item?.questionId,
          answer: matchedAnswer.answer || "",
          response: matchedAnswer.answer || "",
          note: (() => {
            // Check if matchedAnswer.note is a string
            if (typeof matchedAnswer.note === "string") {
              return [[matchedAnswer.note]]; // Wrap the string in a 2D array
            }
        
            // Check if matchedAnswer.note is a 2D array
            if (
              Array.isArray(matchedAnswer.note) &&
              Array.isArray(matchedAnswer.note[0])
            ) {
              return matchedAnswer.note; // Use it as is
            }
        
            // If matchedAnswer.note is not a string or a 2D array, return an empty 2D array
            return [[""]];
          })(),
          proofDocument: (() => {
            // Check if matchedAnswer.proofDocument is an array
            if (Array.isArray(matchedAnswer.proofDocument)) {
              // Check if the first element is an array (indicating a 2D array)
              if (Array.isArray(matchedAnswer.proofDocument[0])) {
                return matchedAnswer.proofDocument;
              } else {
                // It's a 1D array, so wrap it in another array to make it 2D
                return [matchedAnswer.proofDocument];
              }
            } else {
              // If matchedAnswer.proofDocument is not an array, return an empty 2D array
              return [[]];
            }
          })()
        }));
      } else {
        setAnswer((prevState) => ({
          ...prevState,
          note:[['']],
          questionId: item?.questionId,
          moduleId: item?.moduleId,
          questionType: item?.questionType,
          frequency: item?.frequency,
        }));
        setResponse("");
        setNote([[""]]);
        setProofDocument([[]]);
      }
    }
  };

  const handleAccept = async () => {
    const { isSuccess, error, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}validateAnswers`,
      {},
      {
        questionId: item.questionId,
        answerId: answerIdReal,
        questionType: item.questionType,
        remark: remark,
        validation: "ACCEPTED",
        financialYearId: financeObject,
      },
      "POST"
    );

    if (isSuccess) {
      setAuditButton(true);
      getAuditListing();



      // swal({
      //   icon: "success",
      //   title: data.message,
      //   timer: 1000,
      // });
    }

    if (error) {
      // swal({
      //   icon: "error",
      //   title: data.message,
      //   timer: 1000,
      // });
    }
  };

  const handleReject = async () => {
    if (remark) {
      const { isSuccess, error, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}validateAnswers`,
        {},
        {
          questionId: item.questionId,
          answerId: answerIdReal,
          questionType: item.questionType,
          remark: remark,
          validation: "REJECTED",
          financialYearId: financeObject,
        },
        "POST"
      );

      if (isSuccess) {
        getAuditListing();
      }

      if (error) {
        swal({
          icon: "error",
          title: data.message,
          timer: 1000,
        });
      }
    } else {
      swal({
        icon: "error",
        title: "Please enter remark",
        timer: 1000,
      });
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}saveAnswerReportingQuestion`,
      {},
      {
        ...answer,
      },
      "POST"
    );

    if (isSuccess) {
    }
  };

  const handleEditClick = () => {
    setIsEditable(!isEditable);
  };

  const handleLocalResponseChange = (e) => {
    setLocalResponse(e.target.value);
    handleResponseChange(e); // to keep the original response state updated
  };

  const handleResponseChange = (e) => {
    const newResponse = e.target.value;
    if (newResponse === response) {
      setCheck(true);
    } else {
      setCheck(false);
    }

    // if (title.toLowerCase().includes("number")) {
    //   if (!/^\d*$/.test(newResponse)) {
    //     setResponseError("Please enter a valid number.");
    //   } else {
    //     setResponseError("");
    //     setResponse(newResponse);
    //   }
    // } else
    if (title.toLowerCase().includes("email address")) {
      if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(newResponse) &&
        newResponse !== ""
      ) {
        setResponseError("Please enter a valid email address.");
      } else {
        setResponseError("");
        setResponse(newResponse);
      }
    } else if (title.toLowerCase().includes("website")) {
      const urlPattern =
        /^(https?:\/\/)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})(\/\S*)?$/i;
      if (!urlPattern.test(newResponse) && newResponse !== "") {
        setResponseError("Please enter a valid website URL.");
      } else {
        setResponseError("");
        setResponse(newResponse);
      }
    } else {
      setResponseError("");
      setResponse(newResponse);
    }

    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      answer: newResponse,
    }));
  };

  const handleNoteChange = (e) => {
    const newNote = e.target.value;
    setNote([[newNote]]);
    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      note: [[newNote]],
    }));
  };

  const uploadFile = async (files) => {
    const selectedFile = files.target.files[0];
    const timestamp = new Date().getTime(); // Generate a timestamp
    const fileName = `${timestamp}_${selectedFile.name}`;

    const formdata = new FormData();
    formdata.append("file", selectedFile);
    formdata.append("fileName", fileName);
    formdata.append("filePath", "yasil/");

    const requestOptions = {
      header: {
        "Content-Type": "multipart/form-data", // Set the Content-Type header
      },
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${
          config.AUTH_API_URL_COMPANY
        }uploadFile?current_role=${localStorage.getItem("role")}`,
        requestOptions
      );
      const result = await response.text();
      const url = JSON.parse(result);

      // Append the new file URL to the list of proofDocument
      setProofDocument((prevState) => {
        const newProofDocument = [...prevState];

        if (!newProofDocument[0]) {
          newProofDocument[0] = [];
        }

        newProofDocument[0] = [...newProofDocument[0], url.url];

        return newProofDocument;
      });
      setAnswer((prevState) => {
        const newProofDocument = [...prevState.proofDocument];

        // If currentPage doesn't exist yet, initialize it as an empty array
        if (!newProofDocument[0]) {
          newProofDocument[0] = [];
        }

        // Update the specific page
        newProofDocument[0] = [...newProofDocument[0], url.url];

        return {
          ...prevState,
          proofDocument: newProofDocument,
        };
      });
    } catch (error) {}
  };

  useEffect(() => {
    initializeAnswer();
  }, [savedAnswers, item, toDate, fromDate]);

  const confirmDelete = () => {
    setProofDocument((prevState) => {
      // Clone the current proofDocument array
      const newProofDocument = [...prevState];

      // Update the specific page by filtering out the fileToDelete
      newProofDocument[0] = newProofDocument[0].filter(
        (url) => url !== fileToDelete
      );

      return newProofDocument;
    });
    setShowModal(false);
    setFileToDelete(null); // Clear the file to delete
  };

  // Cancel deletion
  const cancelDelete = () => {
    setShowModal(false);
    setFileToDelete(null);
  };
  const [address, setAddress] = useState(null);
  const handleChange = (newAddress) => {
    setAddress(newAddress);
    setAnswer((prevAnswer) => ({
      ...prevAnswer,
      answer: newAddress,
    }));
    setResponse(newAddress);
  };
  function getComponent(components, type) {
    return (
      components.find((component) => component.types.includes(type))
        ?.long_name || ""
    );
  }
  const handleSelect = async (selectedAddress) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      const addressComponents = results[0].address_components;
      setAddress(addressComponents);
      const streetNumber = getComponent(addressComponents, "street_number");
      const route = getComponent(addressComponents, "route");
      const city = getComponent(addressComponents, "locality");
      const state = getComponent(
        addressComponents,
        "administrative_area_level_1"
      );
      const zipcode = getComponent(addressComponents, "postal_code");

      // Combine the components into a single address string
      const completeAddress = `${
        streetNumber ? streetNumber + " " : ""
      }${route}, ${city}, ${state} ${zipcode}`;
      // setResponse(completeAddress);
      setAnswer((prevAnswer) => ({
        ...prevAnswer,
        answer: completeAddress,
      }));
      setAddress(completeAddress);
    } catch (error) {
      console.error("Error selecting location", error);
    }
  };

  const handleDeleteClick = (url) => {
    setFileToDelete(url);
    setShowModal(true);
  };

  const handleFileDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank"; // Opens in a new tab
    link.download = url.split("/").pop();
    document.body.appendChild(link); // Append to body
    link.click();
    document.body.removeChild(link); // Clean up
  };
  return (
    <Form>
      {/* First Row with Response Field */}
      <Row>
        <Col md={12}>
          <Form.Group controlId="formInput11">
            <Form.Label className="custom-label">Response</Form.Label>
            {title
              .toLowerCase()
              .includes("when was the company incorporateds?") ? (
              <DatePicker
                selected={response ? new Date(response) : null}
                onChange={(date) => {
                  setResponse(date ? date.toISOString() : "");
                  setAnswer((prevAnswer) => ({
                    ...prevAnswer,
                    answer: date ? date.toISOString() : "",
                  }));
                }}
                className="form-control"
                dateFormat="yyyy-MM-dd"
              />
            ) : title
                .toLowerCase()
                .includes("what is the financial year for this reports?") ? (
              <div>
                <DatePicker
                  selected={response ? new Date(response) : null}
                  onChange={(date) => {
                    setResponse(date ? date.getFullYear().toString() : "");
                    setAnswer((prevAnswer) => ({
                      ...prevAnswer,
                      answer: date ? date.getFullYear().toString() : "",
                    }));
                  }}
                  className="form-control"
                  showYearPicker
                  dateFormat="yyyy"
                />
              </div>
            ) : title.toLowerCase().includes("paid-up capital?") ? (
              <InputGroup>
                <InputGroup.Text>₹</InputGroup.Text>
                <Form.Control
                  style={{ backgroundColor: "#BFD7E0" }}
                  type="text"
                  value={response || ""}
                  onChange={handleResponseChange}
                  // readOnly={
                  //   menu === "audit" ||
                  //   (assignedToDetails?.assignedTo?.length > 0 &&
                  //     parseInt(assignedToDetails.assignedTo[0], 10) !==
                  //       currentUserId)
                  // }
                  readOnly={
                    menu === "audit" ||
                    (assignedToDetails?.assignedTo?.length > 0 &&
                      !assignedToDetails?.assignedTo?.some(
                        (id) => parseInt(id, 10) === currentUserId
                      )) ||
                    (check && isEditable) ||
                    (assignedToDetails?.dueDate &&
                      new Date(assignedToDetails.dueDate).setHours(0, 0, 0, 0) <
                        new Date().setHours(0, 0, 0, 0))
                  }
                />
              </InputGroup>
            ) : title
                .toLowerCase()
                .includes("address of the registered office?") ? (
              <PlacesAutocomplete
                value={address || response}
                onChange={handleChange}
                onSelect={handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <input
                      {...getInputProps({
                        placeholder: "Type your location... *",
                        className: "location-search-input w-100 mb-2",
                      })}
                      required
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => (
                        <div
                          {...getSuggestionItemProps(suggestion)}
                          key={suggestion.placeId}
                        >
                          {suggestion.description}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            ) : title
                .toLowerCase()
                .includes("address of the corporate office?") ? (
              <PlacesAutocomplete
                value={address || response}
                onChange={handleChange}
                onSelect={handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <input
                      {...getInputProps({
                        placeholder: "Type your location... *",
                        className: "location-search-input w-100 mb-2",
                      })}
                      required
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => (
                        <div
                          {...getSuggestionItemProps(suggestion)}
                          key={suggestion.placeId}
                        >
                          {suggestion.description}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            ) : (
              // <Form.Control
              //   style={{ backgroundColor: "#BFD7E0" }}
              //   type="text"
              //   value={response || ""}
              //   onChange={handleResponseChange}

              //   readOnly={
              //     menu === "audit" ||
              //     (assignedToDetails?.assignedTo?.length > 0 &&
              //       !assignedToDetails?.assignedTo?.some(
              //         (id) => parseInt(id, 10) === currentUserId
              //       ))
              //   }
              // />
              <InputGroup>
                <Form.Control
                  style={{ backgroundColor: "#BFD7E0" }}
                  type="text"
                  as="textarea"
                  value={localResponse || ""}
                  onChange={handleLocalResponseChange}
                  onDoubleClick={() => handleDoubleClick(localResponse)}
                  readOnly={
                    menu === "audit" ||
                    (assignedToDetails?.assignedTo?.length > 0 &&
                      !assignedToDetails?.assignedTo?.some(
                        (id) => parseInt(id, 10) === currentUserId
                      )) ||
                    (check && isEditable) ||
                    (assignedToDetails?.dueDate &&
                      new Date(assignedToDetails.dueDate).setHours(0, 0, 0, 0) <
                        new Date().setHours(0, 0, 0, 0))
                  }
                />
                {response &&
                  check &&
                  assignedToDetails?.assignedTo?.length > 0 &&
                  !assignedToDetails?.assignedTo?.some(
                    (id) => parseInt(id, 10) !== currentUserId
                  ) && (
                    <FaEdit
                      style={{
                        cursor: "pointer",
                        marginLeft: "10px",
                        height: "20px",
                        width: "20px",
                        color: isEditable ? "black" : "#BFD7E0",
                      }}
                      onClick={handleEditClick}
                    />
                  )}
              </InputGroup>
            )}
            {responseError && <p style={{ color: "red" }}>{responseError}</p>}
          </Form.Group>
        </Col>
      </Row>

      {/* Second Row with File Upload */}
      <Row>
        <Col md={6}>
          <Form.Group controlId="formFile">
            <Form.Label className="custom-label">Upload Attachment</Form.Label>
            <Form.Control
              style={{ backgroundColor: "#BFD7E0" }}
              type="file"
              onChange={(e) => uploadFile(e)}
              disabled={menu === "audit"}
            />
          </Form.Group>
          {/* Display Uploaded Files */}
          <>
            <Form.Group>
              <Form.Label className="custom-label">Uploaded Files</Form.Label>
              {proofDocument[0]?.length > 0 &&
                proofDocument[0].map((url, index) => {
                  const segments = url.split("/");
                  const fileName =
                    segments.length > 1
                      ? segments[segments.length - 2] ===
                        segments[segments.length - 1]
                        ? segments[segments.length - 1] // Handle repeated segment
                        : segments[segments.length - 1] // Regular case
                      : url;

                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 5,
                        borderBottom: "1px solid #ccc",
                        paddingBottom: 5,
                      }}
                    >
                      {/* Download Icon */}
                      <FaFileDownload
                        style={{
                          marginLeft: 10,
                          cursor: "pointer",
                          height: "20px",
                          width: "20px",
                          color: "#3F88A5", // Blue color to indicate it's a link
                        }}
                        onClick={() => handleFileDownload(url)}
                        title="Download File" // Tooltip for better UX
                      />

                      {/* File Name */}
                      <span
                        style={{
                          marginLeft: 10,
                          marginRight: 10,
                          wordBreak: "break-all",
                          flexGrow: 1, // Take available space
                          maxWidth: "70%", // Prevent overflow
                        }}
                        title={fileName} // Full file name tooltip
                      >
                        {fileName}
                      </span>

                      {/* Delete Icon */}
                      <FaTrash
                        style={{
                          cursor: "pointer",
                          height: "20px",
                          width: "20px",
                          color: "#3F88A5",
                          marginRight: 20, // Add margin for spacing
                        }}
                        onClick={() => handleDeleteClick(url)}
                        title="Remove File" // Tooltip for better UX
                      />
                    </div>
                  );
                })}
            </Form.Group>

            {/* Confirmation Modal */}
            <DetailModal
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              data={showData}
            />
            <Modal show={showModal} onHide={cancelDelete} centered>
              <Modal.Header closeButton>
                <Modal.Title>Confirm Deletion</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are you sure you want to delete this file?
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={cancelDelete}>
                  Cancel
                </Button>
                <Button variant="danger" onClick={confirmDelete}>
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        </Col>
      </Row>

      {/* Conditional Rendering for Note Field */}
      {item?.applicableNote === 1 && (
        <Row>
          <Col md={12}>
            <Form.Group controlId="formInput12">
              <Form.Label className="custom-label">Note</Form.Label>
              <Form.Control
                style={{
                  backgroundColor: "#BFD7E0",
                  resize: "horizontal",
                  overflow: "auto",
                }}
                type="text"
                as="textarea"
                value={note[0][0]}
                onDoubleClick={() => handleDoubleClick(note)}
                onChange={handleNoteChange}
                readOnly={
                  menu === "audit" ||
                  (assignedToDetails?.assignedTo?.length > 0 &&
                    !assignedToDetails?.assignedTo?.some(
                      (id) => parseInt(id, 10) === currentUserId
                    )) ||
                  (check && isEditable)
                }
              />
            </Form.Group>
          </Col>
        </Row>
      )}
      {menu === "audit" && (
        <Col md={8}>
          <Form.Group controlId="formInput12">
            <Form.Label className="custom-label">Remark</Form.Label>
            <Form.Control
              style={{ backgroundColor: "#BFD7E0" }}
              type="text"
              value={remark}
              onChange={handleRemarkChange}
            />
          </Form.Group>
        </Col>
      )}

      {/* Button Section */}
      <div className="d-flex justify-content-between align-items-center mt-3">
        {/* Placeholder button */}
        <button
          className="btn btn-secondary"
          style={{
            borderColor: "white",
            backgroundColor: "transparent",
            padding: "1%",
            paddingLeft: "1.5%",
            paddingRight: "1.5%",
          }}
        ></button>

        {/* Conditional rendering for buttons */}
        <div
          className="d-flex"
          style={{ width: "30%", justifyContent: "space-between" }}
        >
          {/* <button
            className="btn btn-primary me-5x"
            style={{
              borderColor: "white",
              backgroundColor: "transparent",
              padding: "2%",
              paddingLeft: "2%",
              paddingRight: "2%",
              paddingTop: "3%",
              paddingBottom: "3%",
            }}
          >
            Save the answer
          </button> */}

          {menu === "audit" ? (
            <>
              {auditorId === currentUserId && (
                <>
                {status === "ANSWERED" ? (
                    <>
                     <button
                    className="btn"
                    onClick={handleAccept}
                    disabled={auditButton}
                    style={{
                      borderColor: "white",
                      backgroundColor: "#3F88A5",
                      padding: "2%",
                      paddingLeft: "10%",
                      paddingRight: "10%",
                    }}
                  >
                    Accept
                  </button>
                  <button
                    className="btn"
                    onClick={handleReject}
                    disabled={!response || auditButton}
                    style={{
                      borderColor: "white",
                      backgroundColor: "#3F88A5",
                      padding: "1%",
                      paddingLeft: "10%",
                      paddingRight: "10%",
                    }}
                  >
                    Reject
                  </button>
                    </>
                  ) : status === "ACCEPTED" || status === "REJECTED" ? (
                    <p>This answer has been audited.</p>
                  ) : null}
                  
                </>
              )}
            </>
          ) : (
            assignedToDetails?.assignedTo?.length > 0 &&
            assignedToDetails?.assignedTo?.some(
              (id) => parseInt(id, 10) === currentUserId
            ) && (
              <button
                className="btn btn-primary"
                onClick={handleSubmit}
                disabled={
                  (item?.frequency === "CUSTOM" &&
                    (!selectedPeriod ||
                      !localResponse ||
                      localResponse === response)) ||
                  (item?.frequency !== "CUSTOM" &&
                    (!response || !localResponse || check))
                }
                style={{
                  borderColor: "white",
                  backgroundColor: "#3F88A5",
                  padding: "2%",
                  paddingLeft: "10%",
                  paddingRight: "10%",
                }}
              >
                Submit
              </button>
            )
          )}
        </div>
      </div>
    </Form>
  );
};

export default QualitativeComponent;
