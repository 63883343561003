import React from "react";

import Sidebar from "../../sidebar/sidebar";
import Header from "../../header/header";
import { useState, useEffect } from "react";
import { apiCall } from "../../../_services/apiCall";
import config from "../../../config/config.json";

const NewAuditHistory = (props) => {
    const [financeObjct, setFinanceObjct] = useState();
    const [financialYear, setFinancialYear] = useState();
    const [startingMonth, setStartingMonth] = useState("");
    const [currentUserId, setCurrentUserId] = useState();
    const getFinancialYear = async () => {
        const { isSuccess, data } = await apiCall(
          `${config.POSTLOGIN_API_URL_COMPANY}getFinancialYear`,
          {},
          {}
        );
    
        if (isSuccess) {
          setFinancialYear(data.data[data.data.length - 1].financial_year_value);
          setFinanceObjct(data.data[data.data.length - 1].id);
        }
      };

      useEffect(() => {
        setStartingMonth(
          JSON.parse(localStorage.getItem("currentUser")).starting_month
        );
        setCurrentUserId(JSON.parse(localStorage.getItem("currentUser")).id);
        getFinancialYear();
      }, []);
    
  return (
    <div
      className="d-flex flex-row mainclass"
      style={{ height: "100vh", overflow: "auto" }}
    >
      <div style={{ flex: "0 0 21%", position: "sticky", top: 0, zIndex: 999 }}>
        <Sidebar
          financeObjct={financeObjct}
          dataFromParent={props.location.pathname}
        />
      </div>
      <div style={{ flex: "1 1 79%" }}>
        <div style={{ position: "sticky", top: 0, zIndex: 999 }}>
          <Header />
        </div>
        <div className="main_wrapper p-3">
          <div className="w-100 p-4 ">
           
          </div>
          <div
            className="w-100"
            style={{
              paddingRight: "2.5%",
            }}
          >
           
          </div>
          <div className="w-100 p-4 ">
           
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewAuditHistory