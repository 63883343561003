import React, { useState } from "react";
import Chart from "react-apexcharts";

const HumanRightsTrainingBarChart = () => {
  const [selectedOption, setSelectedOption] = useState("Employees"); // Default option

  // Define data for each radio button option
  const dataOptions = {
    Employees: [
      {
        name: "Male",
        data: [30, 40, 50, 60],
      },
      {
        name: "Female",
        data: [70, 60, 50, 40],
      },
    ],
    PermanentEmployeesCovered: [
      {
        name: "Male",
        data: [20, 30, 40, 50],
      },
      {
        name: "Female",
        data: [80, 70, 60, 50],
      },
    ],
    OtherThanPermanentEmployeesCovered: [
      {
        name: "Male",
        data: [25, 35, 45, 55],
      },
      {
        name: "Female",
        data: [75, 65, 55, 45],
      },
    ],
    Workers: [
      {
        name: "Male",
        data: [15, 25, 35, 45],
      },
      {
        name: "Female",
        data: [85, 75, 65, 55],
      },
    ],
    EmployeesNotCovered: [
      {
        name: "Male",
        data: [10, 20, 30, 40],
      },
      {
        name: "Female",
        data: [90, 80, 70, 60],
      },
    ],
  };

  const chartOptions = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      stackType: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: ["Location 1", "Location 2", "Location 3", "Location 4"],
    },
    legend: {
      position: "top",
    },
    fill: {
      opacity: 1,
    },
    colors: ["#3F88A5", "#E57373"], // Custom colors for Male and Female
  };

  return (
    <div className="container">
      <h3>Human Rights Training</h3>
      <div className="radio-buttons">
        {["Employees", "PermanentEmployeesCovered", "OtherThanPermanentEmployeesCovered", "Workers", "EmployeesNotCovered"].map((option) => (
          <label key={option}>
            <input
              type="radio"
              value={option}
              checked={selectedOption === option}
              onChange={(e) => setSelectedOption(e.target.value)}
            />
            {option.replace(/([A-Z])/g, ' $1').trim()} {/* Formatting option names */}
          </label>
        ))}
      </div>

      <div className="chart-container">
        <Chart
          options={chartOptions}
          series={dataOptions[selectedOption]}
          type="bar"
          height="350"
        />
      </div>
    </div>
  );
};

export default HumanRightsTrainingBarChart;
