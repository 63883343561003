import React from 'react'
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import due from "../../img/Due.svg";
import updated from "../../img/updated.svg";
import done from "../../img/shape.svg";
import defaulted from "../../img/Defaulted.svg";
import { useState } from "react";
import { useEffect } from 'react';
import TopComponentEmission from '../Emission/TopComponentEmission';
import WaterWithdrawal from './WaterWithdrawal';
import TopComponentWater from './TopComponentWater';
import WaterComparison from './WaterComparison';
import WaterRevenueIntensity from './WaterRevenueIntensity';
import WaterStorageTracking from './WaterUnit';
import NegativeWaterConsumption from './NegativeWaterConsumption';


const Water = ({locationOption,timePeriods,financialYearId,graphData}) => {
    const [lastWeekAcitivities, setLastWeekAcitivities] = useState();
    const icons = {
      0: done,
      1: updated,
      2: due,
      3: defaulted,
    };
  
    const lastWeekActivity = async () => {
      const { isSuccess, data } = await apiCall(
        `${config.POSTLOGIN_API_URL_COMPANY}lastWeekActivity`,
        {},
        {},
        "GET"
      );
      if (isSuccess) {
        const data = {
          "Total Water": {
            "number": '1422 M',
            "questionId": [ ]
          },
          
    
          "message": "Good Evening, Sunil Kumar"
        }
        setLastWeekAcitivities(data);
      }
    };

    
  useEffect(() => {
    lastWeekActivity();
  }, []);
  return (
    <div className="progress-container">
    <div className="topcompo">
      {lastWeekAcitivities && (
        <TopComponentWater
          lastWeekAcitivities={lastWeekAcitivities}
          icons={icons}
        />
      )}
    </div>
    {
      locationOption && locationOption.length === 1 && 
      <div className='d-flex flex-column flex-space-between'>
        <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "70vh",marginBottom:"3%" }}
      >
         <div className="firsthalfprogressenergy" style={{width:"50%"}}>
            {/* <LocationWiseEmission/> */}
            {/* <WaterWithdrawal/> */}

        </div>
        <div className="secondhalfprogress" style={{width:"50%"}}>
            {/* <WaterComparison/> */}
        </div>

      </div>
      </div>
    }
    {locationOption && locationOption.length>=2 && <div className="d-flex flex-column flex-space-between">
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "70vh",marginBottom:"3%" }}
      >
        {/* <div className="firsthalfprogressenergy" style={{width:"50%"}}> */}
            {/* <LocationWiseEmission/> */}
            {/* <WaterWithdrawal locationOption = {locationOption} timePeriods = {timePeriods} financialYearId = {financialYearId} graphData = {graphData} /> */}

        {/* </div> */}
        {/* <div className="secondhalfprogress" style={{width:"50%"}}> */}
            <WaterComparison locationOption = {locationOption} timePeriods = {timePeriods} financialYearId = {financialYearId} graphData = {graphData}/>
        {/* </div> */}
      </div>
  
      {/* <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "70vh",marginBottom:"3%" }}
      >
        <div className="firsthalfprogressenergy" style={{width:"50%"}}>
            <WaterRevenueIntensity/>
          
        </div>
        <div className="secondhalfprogress" style={{width:"50%"}}>
        <WaterStorageTracking/>
        </div>
      </div>
      <div
        className="d-flex flex-row flex-space-between"
        style={{ height: "70vh",marginBottom:"3%" }}
      >
        <div className="firsthalfprogressenergy" style={{width:"50%"}}>
        <WaterStorageTracking/>
       
          
        </div>
        <div className="secondhalfprogress" style={{width:"50%"}}>
          <NegativeWaterConsumption/>
        </div>
      </div> */}

    </div>}
  </div>
  )
}

export default Water