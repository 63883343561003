import React from "react";
import { apiCall } from "../../../../../_services/apiCall";
import config from "../../../../../config/config.json";
import FrameworkModal from "./FrameworkModal";
import './DropdownStyles.css'
import { useState, useEffect } from "react";

const TopComponent = (
    {
      selectedFrameworks,setSelectedFrameworks,
        financialYear,
        frameworkValue,
        setFinancialYear,
        financialYearId,
        setFinancialYearId,
        handleFinancialYearChange,
        setFrameworkValue,
        selectedFramework,
        setSelectedFramework,
        onSelectFrameworkHandler
    }
) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [availableFrameworks, setAvailableFrameworks] = useState([]);

  const handleSelectFramework = (framework) => {
    const updatedFrameworks = [...selectedFrameworks, framework];
    setSelectedFrameworks(updatedFrameworks);
    setSelectedFramework(updatedFrameworks);
    setFrameworkValue((prevFrameworks) => [...prevFrameworks, framework]);
    onSelectFrameworkHandler(updatedFrameworks);
    setIsModalOpen(false);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    // Filter available frameworks by excluding selected ones
    const updatedAvailableFrameworks = frameworkValue?.filter(framework => 
      !selectedFramework.some(selected => selected.id === framework.id)
    );
    setAvailableFrameworks(updatedAvailableFrameworks);
  }, [selectedFramework, frameworkValue]);

  const handleAddFramework = () => {
    setIsModalOpen(true);
  };
  return (
    <div style={{ display: "flex",flexDirection:"column", backgroundColor: "transparent",paddingTop:"1.5%" }}>
      <div style={{ flex: 1 }}>
        <h2 style={{
            color: "#011627",

            fontFamily: "Open Sans",
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "normal",
        }}>Subscribed Frameworks</h2>
      </div>
      <div style={{ flex: 1 , width:"100%",display:"flex", justifyContent:"space-between"}}>
      <div className="dropdown-container">
      {financialYear && financialYear?.length === 1 ? (
        <select
          name="tab_name"
          onChange={handleFinancialYearChange}
          className="custom-dropdown"
        >
          {financialYear?.map((item, key) => (
            <option key={key} value={item.id}>
              {item.financial_year_value}
            </option>
          ))}
        </select>
      ) : (
        <select
          name="tab_name"
          value={
            financialYearId ||
            (financialYear?.length > 0
              ? financialYear[financialYear.length - 1].id
              : "")
          }
          onChange={handleFinancialYearChange}
          className="custom-dropdown"
        >
          <option>Select Financial Year</option>
          {financialYear?.map((item, key) => (
            <option key={key} value={item.id}>
              {item.financial_year_value}
            </option>
          ))}
        </select>
      )}
      <i className="dropdown-icon"></i> {/* Icon inside the dropdown */}
    </div>
    <div>
    <button className="add-framework-button" onClick={handleAddFramework}>
        <span style={{fontSize:"14px"}} className="mr-1 ms-0" >+</span> ADD FRAMEWORK
      </button>
      {isModalOpen && (
        <FrameworkModal
          frameworks={availableFrameworks}
          onSelectFramework={handleSelectFramework}
          onClose={closeModal}
        />
      )}
    </div>
      </div>
    </div>
  );
};

export default TopComponent;
