import React, { useEffect } from "react";
import TopComponentEnergy from "./TopComponentEnergy";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import due from "../../img/Due.svg";
import updated from "../../img/updated.svg";
import done from "../../img/shape.svg";
import defaulted from "../../img/Defaulted.svg";
import { useState } from "react";
import TotalEnergyConsumption from "./TotalEnergyConsumption";
import "./energy.css";
import ProductEnergyConsumption from "./ProductEnergyConsumption";
import LocationEnergyConsumption from "./LocationEnergyConsumption";
import TimeBasedProductMix from "./TimeBasedProductMix";
import LocationWise from "./LocationWise";
import OverTheYear from "./OverTheYear";
import ProductWiseEnergyConsumption from "./ProductWiseEnergyConsumption";
import EnergyIntensity from "./EnergyIntensity";

const Energy = ({locationOption,timePeriods,financialYearId,graphData}) => {
  const location = 2;
  const [lastWeekAcitivities, setLastWeekAcitivities] = useState();
  const icons = {
    0: done,
    1: updated,
    2: due,
    3: defaulted,
  };

  const lastWeekActivity = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}lastWeekActivity`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      // console.log(data?.data,"rtyuioiuytrefrtyui")
      const data = {
        "Total Energy": {
          "number": '12.57 M',
          "questionId": [ ]
        },
        "Renewable Energy": {
          "number": '2.34 M',
       "questionId": []
        },
        "Non Renewable Energy": {
          "number": '10.23 M',
          "questionId": []
        },
  
        "message": "Good Evening, Sunil Kumar"
      }
      setLastWeekAcitivities(data);
    }
  };

  useEffect(() => {
    lastWeekActivity();
  }, []);
  return (
    <div className="progress-container">
      <div className="topcompo">
        {lastWeekAcitivities && (
          <TopComponentEnergy
            lastWeekAcitivities={lastWeekAcitivities}
            icons={icons}
          />
        )}
      </div>
      {location === 1 && (
        <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy">
              <TotalEnergyConsumption numLocations={location} />
            </div>
            <div className="secondhalfprogress">
              <ProductEnergyConsumption />
            </div>
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="secondhalfprogress">
              <LocationEnergyConsumption />
            </div>
            <div className="firsthalfprogressenergy">
              <TimeBasedProductMix />
            </div>
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="secondhalfprogress">
              <LocationWise />
            </div>
            <div
              className="firsthalfprogressenergy"
              style={{ marginLeft: "2%" }}
            >
              <OverTheYear />
            </div>
          </div>
        </div>
      )}
      {location >= 1 && (
        <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%",width:"76vw" }}
          >
            {/* <div className="firsthalfprogressenergy">
              <TotalEnergyConsumption />
            </div> */}
            {/* <div className="secondhalfprogress"> */}
              <ProductWiseEnergyConsumption locationOption = {locationOption} timePeriods = {timePeriods} financialYearId = {financialYearId} graphData = {graphData}/>
            {/* </div> */}
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%", width:"76vw"}}
          >
            {/* <div className="firsthalfprogressenergy"> */}
              <EnergyIntensity locationOption = {locationOption} timePeriods = {timePeriods} financialYearId = {financialYearId} graphData = {graphData}/>
            {/* </div> */}
            {/* <div className="secondhalfprogress">
              <EnergyEfficiency />
            </div> */}
          </div>
          {/* <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <EnergySavingOverview />
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "70vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy">
              <OnSiteRenew />
            </div>
            <div className="secondhalfprogress">
              <GreenEnergyPurchase/>
            </div>
          </div> */}
        </div>
      )}
    </div>
  );
};

export default Energy;
