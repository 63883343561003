import React, { useState } from "react";
import Chart from "react-apexcharts";

const IncidentRateAnalysis = () => {
  // Data structure for Time and Location
  const data = {
    Time: {
      Q1: [
        { name: "No of Employees", type: "column", data: [60, 40, 30] }, // Location 1, 2, 3
        { name: "Incident Rate", type: "line", data: [45, 55, 50] },
      ],
      Q2: [
        { name: "No of Employees", type: "column", data: [50, 30, 60] },
        { name: "Incident Rate", type: "line", data: [60, 50, 55] },
      ],
      Q3: [
        { name: "No of Employees", type: "column", data: [80, 40, 60] },
        { name: "Incident Rate", type: "line", data: [50, 65, 60] },
      ],
      Q4: [
        { name: "No of Employees", type: "column", data: [70, 50, 40] },
        { name: "Incident Rate", type: "line", data: [40, 45, 50] },
      ],
    },
    Location: {
      Location1: [
        { name: "No of Employees", type: "column", data: [60, 50, 80, 70] }, // Q1, Q2, Q3, Q4
        { name: "Incident Rate", type: "line", data: [45, 60, 50, 40] },
      ],
      Location2: [
        { name: "No of Employees", type: "column", data: [40, 30, 40, 50] },
        { name: "Incident Rate", type: "line", data: [55, 50, 65, 45] },
      ],
      Location3: [
        { name: "No of Employees", type: "column", data: [30, 60, 60, 40] },
        { name: "Incident Rate", type: "line", data: [50, 55, 60, 50] },
      ],
    },
  };
  const [timeLocation, setTimeLocation] = useState("Time");
  const [selectedOption, setSelectedOption] = useState("Q1");

  const options = {
    chart: {
      height: 350,
      type: "line",
      stacked: false,
    },
    stroke: {
      width: [0, 2],
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        columnWidth: "40%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    labels: ["Q1", "Q2", "Q3", "Q4"],
    xaxis: {
      categories: ["Q1", "Q2", "Q3", "Q4"],
    },
    yaxis: [
      {
        title: {
          text: "No of Employees",
        },
      },
      {
        opposite: true,
        title: {
          text: "Incident Rate",
        },
      },
    ],
    legend: {
      markers: {
        fillColors: ["#3A60E6", "#E95757", "#7CC3C3"],
      },
    },
  };

  const handleTimeLocationChange = (event) => {
    setTimeLocation(event.target.value);
    setSelectedOption(timeLocation === "Time" ? "Q1" : "Location1");
  };

  return (
    <div className="container">
      <h2 style={{ fontSize: "1.5em" }}>Incident Rate Analysis Over Time</h2>
      <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
        <div className="radio-buttons">
          <label>
            <input
              type="radio"
              value="Time"
              checked={timeLocation === "Time"}
              onChange={handleTimeLocationChange}
            />{" "}
            Time
          </label>
          <label style={{ marginLeft: "20px" }}>
            <input
              type="radio"
              value="Location"
              checked={timeLocation === "Location"}
              onChange={handleTimeLocationChange}
            />{" "}
            Location
          </label>
        </div>
      </div>

      <div className="radio-buttons" style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
        {timeLocation === "Time"
          ? ["Q1", "Q2", "Q3", "Q4"].map((quarter) => (
              <label key={quarter} style={{ marginLeft: "10px" }}>
                <input
                  type="radio"
                  value={quarter}
                  checked={selectedOption === quarter}
                  onChange={(e) => setSelectedOption(e.target.value)}
                />{" "}
                {quarter}
              </label>
            ))
          : ["Location1", "Location2", "Location3"].map((location) => (
              <label key={location} style={{ marginLeft: "10px" }}>
                <input
                  type="radio"
                  value={location}
                  checked={selectedOption === location}
                  onChange={(e) => setSelectedOption(e.target.value)}
                />{" "}
                {location}
              </label>
            ))}
      </div>

      <Chart
        options={options}
        series={data[timeLocation][selectedOption]}
        type="line"
        height={250}
      />
    </div>
  );
};

export default IncidentRateAnalysis;
