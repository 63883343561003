import React from "react";
import Table from "react-bootstrap/Table";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import { useEffect, useState } from "react";
import "./teamworkload.css";
import "../ProgressBySector/sectorprogress.css"

const TeamWorkLoad = ({fromDate,toDate,financialYearId}) => {
  const [teamWorkloadData, setTeamWorkloadData] = useState([]);

  const onSelect = (data) => {
    localStorage.setItem("questionIds", data);
    window.location.href = "/#/sector_questions";
  };
  const teamWorkloadProgess = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}teamWorkloadProgess`,
      {},
      {fromDate:fromDate,
        toDate:toDate,
        financialYearId: financialYearId },
      "GET"
    );
    if (isSuccess) {
      setTeamWorkloadData(data?.data?.teamWorkloadResults);
    }
  };
  useEffect(() => {
    teamWorkloadProgess();
  }, [fromDate,toDate,financialYearId]);

  return (
    <div
      style={{
        width: "100%",
        background: "white",
        borderRadius: "10px",
        padding: "10px",
        height: "100%",
      }}
    >
      <div>
        <div
          style={{
            color: "#011627",
            fontSize: 22,
            paddingLeft: "5px",
            fontFamily: "Open Sans",
            fontWeight: "600",
          }}
        >
          Team Work Load
        </div>
      </div>
      <div className="legend">
        <div className="legend-item">
          <div className="legend-color assigned"></div>
          <div className="legend-text">Assigned Questions</div>
        </div>
      </div>
      <div className="scrollable-table mt-2 scroll-container">
        <Table className="">
          <thead>
            <tr>
              <th className="id-column">#</th>
              <th className="name-column">Name</th>
              <th className="progress-column">Progress</th>
              <th className="count-column">Count</th>
            </tr>
          </thead>
          <tbody className="scroll-container">
            {teamWorkloadData.map((item) => {
              const width1 =
                (item.totalQuestions / item.totalCompanyQuestions) * 100;
              const width2 =
                ((item.totalCompanyQuestions - item.totalQuestions) /
                  item.totalCompanyQuestions) *
                100;

              return (
                <tr key={item.userId}>
                  <td className="id-column">{item.userId}</td>
                  <td className="name-column">{`${item.firstName} ${item.lastName}`}</td>
                  <td className="progress-column">
                    <div className="bar-container">
                      <div
                        className="bar accepted"
                        style={{ width: `${width1}%` }}
                      ></div>
                      <div
                        className="bar not-responded"
                        style={{ width: `${width2}%` }}
                      ></div>
                    </div>
                  </td>
                  <td
                    className="count-column"
                    onClick={() => onSelect(item.questionIds)}
                  >
                    <div className="count-container">
                      <span>{`${item.totalQuestions}/${item.totalCompanyQuestions}`}</span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default TeamWorkLoad;
