import React from "react";
import { apiCall } from "../../_services/apiCall";
import config from "../../config/config.json";
import due from "../../img/Due.svg";
import updated from "../../img/updated.svg";
import { Row, Col } from "react-bootstrap";
import done from "../../img/shape.svg";
import defaulted from "../../img/Defaulted.svg";
import { useState } from "react";
import { useEffect } from "react";
import TopComponentEnvironment from "./TopComponentEnvironment";
import Overview from "./Overview";
import DashboardCharts from "./DashboardCharts";
import DashboardChartsTwo from "./DashboardChartsTwo";
import TotalEmissionGenerated from "../Emission/TotalCommissionGranted";
import TotalEnergyConsumption from "../Energy/TotalEnergyConsumption";
import TotalWaterConsumed from "./TotalWwaterConsumed";
import TotalWasteGenerated from "./TotalWasteDisposed";
import TotalWaterWithdrawn from "./TotalWaterWithdrawn,";
import WasteDisposedEn from "./WasteDisposedEn";
import GreenProcurementRate from "./GreenProcurementRate";
import IndustryBenchmarkComparison from "./IndustryBenchmark";
import ProgressTowardsESGTargets from "./ProgressTowardsESGCharts";

const Environment = ({locationOption,timePeriods,financialYearId,graphData}) => {
  const location = 2;
  const [lastWeekAcitivities, setLastWeekAcitivities] = useState();
  const icons = {
    done: done,
    updated: updated,
    due: due,
    pending: defaulted,
  };

  const lastWeekActivity = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.POSTLOGIN_API_URL_COMPANY}lastWeekActivity`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      const data = {
        "Total Energy": {
          "number": '12.57 M (GJ)',
          "questionId": [ ]
        },
        "Total Emission": {
          "number": '2009 tCO2e',
       "questionId": []
        },
        "Total Water": {
          "number": '1422 M (KL)',
          "questionId": []
        },
        "Total Waste": {
          "number": '2354 (mt)',
          "questionId": []
        },
  
        "message": "Good Evening, Sunil Kumar"
      }
      setLastWeekAcitivities(data);
    }
  };

  useEffect(() => {
    lastWeekActivity();
  }, []);
  return (
    <div className="progress-container">
      <div className="topcompo">
        {lastWeekAcitivities && (
          <TopComponentEnvironment
            lastWeekAcitivities={lastWeekAcitivities}
            icons={icons}
          />
        )}
      </div>

      {location === 1 && (
        <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "50vh", marginBottom: "3%" }}
          >
            <Overview />
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "55vh", marginBottom: "3%" }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                background: "transparent",
                marginLeft: "0.5%",
              }}
            >
              <DashboardCharts />
            </div>
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "45vh", marginBottom: "3%" }}
          >
            <DashboardChartsTwo />
          </div>
        </div>
      )}
      {location === 2 && (
        <div className="d-flex flex-column flex-space-between">
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "50vh", marginBottom: "3%" }}
          >
            <Overview />
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "60vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy">
              <TotalEmissionGenerated />
            </div>
            <div className="secondhalfprogress">
              <TotalEnergyConsumption />
            </div>
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "60vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy">
              <TotalWaterConsumed/>
            </div>
            <div className="secondhalfprogress">
              <TotalWasteGenerated/>
            </div>
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "60vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy">
              <TotalWaterWithdrawn/>
            </div>
            <div className="secondhalfprogress">
              <WasteDisposedEn/>
            </div>
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "60vh", marginBottom: "3%" }}
          >
            <div className="firsthalfprogressenergy">
              <GreenProcurementRate/>
            </div>
            <div className="secondhalfprogress">
              <IndustryBenchmarkComparison/>
            </div>
          </div>
          <div
            className="d-flex flex-row flex-space-between"
            style={{ height: "60vh", marginBottom: "3%" }}
          >
           <ProgressTowardsESGTargets/>
          </div>
        </div>
      )}
    </div>
  );
};

export default Environment;
